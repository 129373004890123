import React from 'react';

import dayjs from 'dayjs';
import { lighten, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import NumberUtils from '../../commons/utils/numberUtils';
import theme from '../../theme';

function getServiceName(app) {
  if (app.service != null) {
    return app.service.name;
  }
  if (app.appointmentHasService != null && app.appointmentHasService.length > 0) {
    return app.appointmentHasService.map((s) => s.service.name).join(',');
  }
  return '-';
}

function checkIsPaymentOnSameDateAsApp(obj) {
  if (obj.appointment.accountsReceivableId != null) {
    return false;
  }

  if (obj.paymentList && obj.paymentList.length > 0) {
    let areAllSameDate = true;
    const appDate = dayjs(obj.appointment.date, 'YYYY-MM-DD');
    obj.paymentList.forEach((payment) => {
      areAllSameDate = areAllSameDate && dayjs(payment.dueDate, 'YYYY-MM-DD').isSame(appDate, 'date');
    });
    return areAllSameDate;
  }

  return false;
}

// COMPONENT
function CompanyAppointmentPaymentsTable({ dataSource }) {
  let total = 0;
  let hasComanda = false;
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Horario</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Serviço</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Valor Pago</TableCell>
            <TableCell>Pgto Automatico?</TableCell>
            <TableCell>Data Pagamento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource?.map((obj, i) => {
            const isPaymentOnSameDateAsApp = checkIsPaymentOnSameDateAsApp(obj);
            const backgroundColor = isPaymentOnSameDateAsApp
              ? lighten(theme.palette.success.main, 0.7)
              : lighten(theme.palette.error.main, 0.7);

            total += obj.appointment.accountsReceivableId == null ? obj.appointment.price : 0;

            if (obj.appointment.accountsReceivableId != null) {
              hasComanda = true;
            }

            return (
              <TableRow key={obj.appointment.id} style={{ backgroundColor }}>
                <TableCell>{obj.appointment?.customer?.name ?? '-'}</TableCell>
                <TableCell>{getServiceName(obj.appointment)}</TableCell>
                <TableCell>{dayjs(obj.appointment.date, 'YYYY-MM-DD').format('L')}</TableCell>
                <TableCell>{`${obj.appointment.startTime} - ${obj.appointment.duration} min`}</TableCell>

                <TableCell>
                  {obj.appointment.accountsReceivableId == null
                    ? NumberUtils.toCurrency(obj.appointment.price)
                    : 'Comanda'}
                </TableCell>
                <TableCell>
                  {obj.appointment.accountsReceivableId == null
                    ? NumberUtils.toCurrency(
                        !obj.appointment.nonPaid ? obj.appointment.price : obj.appointment.totalPaid,
                      )
                    : 'Comanda'}
                </TableCell>

                <TableCell>
                  {obj.appointment.accountsReceivableId == null &&
                  !obj.appointment.nonPaid &&
                  obj.appointment.totalPaid == null
                    ? 'Sim'
                    : ''}
                </TableCell>
                <TableCell>
                  <div>
                    {obj.paymentList &&
                      obj.paymentList
                        .map(
                          (p) =>
                            `${NumberUtils.toCurrency(p.value)} (${p.paymentMethod}) - ${dayjs(
                              p.dueDate,
                              'YYYY-MM-DD',
                            ).format('D/M/YY')}`,
                        )
                        .join(', ')}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell>RESUMO</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{NumberUtils.toCurrency(total)}</TableCell>
            <TableCell>{hasComanda ? 'Comanda não inclui no total' : ''}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

export default CompanyAppointmentPaymentsTable;
