import HttpService from './commons/HttpService';

const CompanyInternalNotesService = {
  save(object) {
    if (object.id && object.id > 0) {
      return this.update(object);
    }
    return this.create(object);
  },
  create(object) {
    return HttpService.post('/companyInternalNotes', object);
  },
  update(object) {
    return HttpService.put(`/companyInternalNotes/${object.id}`, object);
  },
  delete(object) {
    return HttpService.delete(`/companyInternalNotes/${object.id}`);
  },
  getById(id) {
    return HttpService.get(`/companyInternalNotes/${id}`);
  },
};

export default CompanyInternalNotesService;
