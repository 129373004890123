import React from 'react';

import { InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// COMPONENT
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter, inputFilterType } }) {
  return (
    <TextField
      variant="standard"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      type={inputFilterType ?? 'text'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FontAwesomeIcon icon="magnifying-glass" color="#aaaaaa" size="xs" />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default DefaultColumnFilter;
