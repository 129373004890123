import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
  styled,
  IconButton,
} from '@mui/material';

import { Formik, Form } from 'formik';

import yup from '../../../commons/validators/customYup';
import showNotification from '../../../commons/helpers/showNotification';
import LabelStatus from '../../../components/labels/LabelStatus';
import CompanyInternalNotesService from '../../../services/CompanyInternalNotesService';
import { FormTextField } from '../../../components/forms/FormikFields';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';

const MAX_COMMENT_LENGTH = 800;
const COMMENT_INPUT_PROPS = { multiline: true, autoFocus: true, rows: 4, placeholder: 'Digite aqui...' };

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  notes: yup.string().trim().max(MAX_COMMENT_LENGTH).required(),
});

const DEFAULT_VALUES = {
  notes: '',
};

// COMPONENT
function EditCompanyNotesModal({ open, onClose, companyId, companyInternalNotes, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setInitialValues({
        notes: companyInternalNotes?.notes ?? '',
        timestamp: new Date().getTime(),
      });
    }
  }, [open, companyInternalNotes]);

  const myHandleSubmit = (values, actions) => {
    setIsLoading(true);
    CompanyInternalNotesService.save({
      companyId: companyId,
      notes: values.notes,
      id: companyInternalNotes?.id,
    })
      .then((resp) => {
        showNotification('success', 'Atualizado com sucesso.');
        onSave(resp);
        onClose();
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  return (
    <>
      {/* MAIN DIALOG */}
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="sm">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ dirty, isSubmitting, values }) => {
            const notesLength = values.notes.length;
            return (
              <Form>
                <DialogTitle>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="h5" component="span">
                      {companyInternalNotes?.id ? 'Atualizando' : 'Criando'} anotação -{' '}
                      <LabelStatus
                        status={notesLength > MAX_COMMENT_LENGTH ? 'error' : null}
                        component="span"
                        variant="overline"
                      >
                        {notesLength}/{MAX_COMMENT_LENGTH}
                      </LabelStatus>
                    </Typography>

                    {companyInternalNotes?.id && (
                      <IconButton
                        onClick={() => {
                          const answer = window.confirm('Você tem certe que quer deletar?');
                          if (answer) {
                            setIsLoading(true);
                            CompanyInternalNotesService.delete({
                              id: companyInternalNotes?.id,
                            })
                              .then(() => {
                                showNotification('success', 'Deletado com sucesso.');
                                onSave(null);
                                onClose();
                              })
                              .finally(() => {
                                setIsLoading(false);
                              });
                          }
                        }}
                        sx={{ mr: 1 }}
                      >
                        <FontAwesomeIconStatus icon="trash" status="error" />
                      </IconButton>
                    )}
                  </Box>
                </DialogTitle>
                <DialogContent dividers>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {isLoading && <CircularProgress />}
                    <FormTextField fullWidth name="notes" inputProps={COMMENT_INPUT_PROPS} />
                  </Box>
                </DialogContent>

                <DialogActionsStyled>
                  <Button variant="outlined" onClick={onClose}>
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!dirty || isSubmitting || isLoading}
                  >
                    Salvar
                  </Button>
                </DialogActionsStyled>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}

export default EditCompanyNotesModal;
