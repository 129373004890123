import { Navigate } from 'react-router-dom';
import DashboardLayout from '../components/layouts/DashboardLayout';
import MainLayout from '../components/layouts/MainLayout';

// INTERNAL EXAMPLES
import Playground from '../pages/examples/Playground';
import ComponentExamples from '../pages/examples/ComponentExamples';
import LandingPage from '../pages/examples/LandingPage';
import ExampleQuestionListPage from '../pages/examples/crud/ExampleQuestionListPage';
import ExampleQuestionFormPage from '../pages/examples/crud/ExampleQuestionFormPage';
import ExampleQuestionBasePage from '../pages/examples/crud/ExampleQuestionBasePage';
// INTERNAL EXAMPLES

import Login from '../pages/others/Login';
import NotFound from '../pages/others/NotFound';
import AnamneseBasePage from '../pages/anamnese/AnamneseBasePage';
import AnamneseListPage from '../pages/anamnese/AnamneseListPage';
import AnamneseFormPage from '../pages/anamnese/AnamneseFormPage';
import ManageCompanyBasePage from '../pages/manageCompany/ManageCompanyBasePage';
import ManageCompanyPage from '../pages/manageCompany/ManageCompanyPage';
import ListUsersPage from '../pages/manageCompany/ListUsersPage';
import { ROLES_ADMIN } from '../commons/constants';
import RequireRolesPage from './RequireRolesPage';
import FeatureBasePage from '../pages/feature/FeatureBasePage';
import FeatureListPage from '../pages/feature/FeatureListPage';
import FeatureFormPage from '../pages/feature/FeatureFormPage';
import EmployeeBasePage from '../pages/employee/EmployeeBasePage';
import EmployeeListPage from '../pages/employee/EmployeeListPage';
import EmployeeFormPage from '../pages/employee/EmployeeFormPage';
import ProfileBasePage from '../pages/profile/ProfileBasePage';
import ProfileEditPage from '../pages/profile/ProfileEditPage';
import DefinePassword from '../pages/others/DefinePassword';
import ListStripeUsersPage from '../pages/manageCompany/ListStripeUsersPage';
import AppleOrderSearchPage from '../pages/manageCompany/AppleOrderSearchPage';
import TransferSubscriptionPage from '../pages/manageCompany/TransferSubscriptionPage';
import AppleTransactionHistorySearchPage from '../pages/manageCompany/AppleTransactionHistorySearchPage';
import AdmDashboardPage from '../pages/manager/AdmDashboardPage';
import AdmDashboardBasePage from '../pages/manager/AdmDashboardBasePage';
import AdmDashboardNewAccountsPage from '../pages/manager/AdmDashboardNewAccountsPage';
import AdmDashboardSubsPage from '../pages/manager/AdmDashboardSubsPage';
import CompanyOnlineLinkSearchPage from '../pages/manageCompany/CompanyOnlineLinkSearchPage';
import CompanyAppointmentSearchPage from '../pages/manageCompany/CompanyAppointmentSearchPage';
import AdmDashboardSubsByNumberOfUsersPage from '../pages/manager/AdmDashboardSubsByNumberOfUsersPage';
import CompanyAppointmentPaymentsSearchPage from '../pages/manageCompany/CompanyAppointmentPaymentsSearchPage';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'home-page', element: <LandingPage /> },
      {
        path: 'examples',
        element: <ComponentExamples />,
      },
      {
        path: 'playground',
        element: <Playground />,
      },
      {
        path: 'crudexample',
        element: <ExampleQuestionBasePage />,
        children: [
          {
            index: true,
            element: <ExampleQuestionListPage />,
          },
          {
            path: ':id',
            element: <ExampleQuestionFormPage />,
          },
          {
            path: 'novo',
            element: <ExampleQuestionFormPage />,
          },
        ],
      },
      {
        path: 'anamnese',
        element: (
          <RequireRolesPage requiredRoles={ROLES_ADMIN}>
            <AnamneseBasePage />
          </RequireRolesPage>
        ),

        children: [
          {
            index: true,
            element: <AnamneseListPage />,
          },
          {
            path: 'edit',
            element: <AnamneseFormPage />,
          },
          {
            path: 'novo',
            element: <AnamneseFormPage />,
          },
        ],
      },
      {
        path: 'manage-company',
        element: <ManageCompanyBasePage />,
        children: [
          {
            index: true,
            element: <ManageCompanyPage />,
          },
          {
            path: ':companyId',
            element: <ManageCompanyPage />,
          },
          {
            path: 'list-users',
            element: <ListUsersPage />,
          },
          {
            path: 'list-stripe-users',
            element: <ListStripeUsersPage />,
          },
          {
            path: 'apple-order-search',
            element: <AppleOrderSearchPage />,
          },
          {
            path: 'apple-transaction-history-search',
            element: <AppleTransactionHistorySearchPage />,
          },
          {
            path: 'transfer-subscription',
            element: <TransferSubscriptionPage />,
          },
          {
            path: 'online-link-search',
            element: <CompanyOnlineLinkSearchPage />,
          },
          {
            path: 'appointment-search',
            element: <CompanyAppointmentSearchPage />,
          },
          {
            path: 'appointment-payments',
            element: <CompanyAppointmentPaymentsSearchPage />,
          },
        ],
      },
      {
        path: 'adm',
        element: <AdmDashboardBasePage />,
        children: [
          {
            index: true,
            element: <AdmDashboardPage />,
          },
          {
            path: 'new-accounts',
            element: <AdmDashboardNewAccountsPage />,
          },
          {
            path: 'subs',
            element: <AdmDashboardSubsPage />,
          },
          {
            path: 'subs-by-number-of-users',
            element: <AdmDashboardSubsByNumberOfUsersPage />,
          },
        ],
      },
      {
        path: 'feature',
        element: <FeatureBasePage />,
        children: [
          {
            index: true,
            element: <FeatureListPage />,
          },
          {
            path: ':id',
            element: <FeatureFormPage />,
          },
          {
            path: 'novo',
            element: <FeatureFormPage />,
          },
        ],
      },
      {
        path: 'employee',
        element: <EmployeeBasePage />,
        children: [
          {
            index: true,
            element: <EmployeeListPage />,
          },
          {
            path: ':id',
            element: <EmployeeFormPage />,
          },
          {
            path: 'novo',
            element: <EmployeeFormPage />,
          },
        ],
      },
      {
        path: 'profile',
        element: <ProfileBasePage />,
        children: [
          {
            index: true,
            element: <ProfileEditPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'define-password', element: <DefinePassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/home-page" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
