import HttpService from './commons/HttpService';

const SuperUserService = {
  getNewUsers(startDate, endDate) {
    return HttpService.get('/superUser/newUsers', { params: { startDate, endDate } });
  },
  getCompaniesExpiring() {
    return HttpService.get('/superUser/companiesExpiring');
  },
  getCompanyInfo({ phoneOrEmail, emailOnly }) {
    return HttpService.get('/superUser/companyInfo', { params: { phoneOrEmail, emailOnly } });
  },
  getCompanyInfoByCompanyId({ companyId }) {
    return HttpService.get('/superUser/companyInfoByCompanyId', { params: { companyId } });
  },
  getCompanyOnlineLinkInfo({ companyId }) {
    return HttpService.get('/superUser/companyOnlineLinkInfo', { params: { companyId } });
  },
  getCountAppointmentByCompanyId({ companyId }) {
    return HttpService.get('/superUser/countAppointmentByCompanyId', { params: { companyId } });
  },
  getTopServicePackagesByCompanyId({ companyId }) {
    return HttpService.get('/superUser/topServicePackagesByCompanyId', { params: { companyId } });
  },
  getAllCreditCardRateByCompanyId({ companyId }) {
    return HttpService.get('/superUser/creditCardRates', { params: { companyId } });
  },
  getDeditCardRateByCompanyId({ companyId }) {
    return HttpService.get('/superUser/debitCardRate', { params: { companyId } });
  },
  getTopServicesByCompanyId({ companyId }) {
    return HttpService.get('/superUser/topServicesByCompanyId', { params: { companyId } });
  },
  getCompanyInfoCpfCnpj({ cpfCnpj }) {
    return HttpService.get('/superUser/companyInfoByCpfCnpj', { params: { cpfCnpj } });
  },
  getCompanyInfoDeleted({ phoneOrEmail, emailOnly }) {
    return HttpService.get('/superUser/companyInfoDeleted', { params: { phoneOrEmail, emailOnly } });
  },
  updateMaxNumberOfUsers(companyInfoForm) {
    return HttpService.post('/superUser/updateMaxNumberUsers', companyInfoForm);
  },
  updateMaxNumberOfUsersCourtesy(companyInfoForm) {
    return HttpService.post('/superUser/updateMaxNumberUsersCourtesy', companyInfoForm);
  },
  deleteUnusedCustomers(companyId) {
    return HttpService.post('/superUser/deleteUnusedCustomers', { companyId });
  },
  deleteCompany(companyId) {
    return HttpService.delete(`/superUser/companyInfo/${companyId}`);
  },
  resetCompany(companyId) {
    return HttpService.post(`/superUser/companyInfo/${companyId}/reset`);
  },
  getCompanyEmployees(companyId) {
    return HttpService.get('/superUser/companyEmployees', { params: { companyId } });
  },
  updatePhoneNumber(companyPhoneNumberForm) {
    return HttpService.post('/superUser/companyPhoneNumber', companyPhoneNumberForm);
  },
  updateEmailAddress(companyEmailAddressForm) {
    return HttpService.post('/superUser/companyEmailAddress', companyEmailAddressForm);
  },

  getCompanyDiscount(companyId) {
    return HttpService.get('/superUser/companyDiscount', { params: { companyId } });
  },
  getPlansForCompany(companyId) {
    return HttpService.get('/superUser/plans', { params: { companyId } });
  },
  updateCompanyDiscount(updateDiscountForm) {
    return HttpService.post('/superUser/companyDiscount', updateDiscountForm);
  },
  getDiscountList() {
    return HttpService.get('/superUser/discountList');
  },

  createTempPassword(userId) {
    return HttpService.post('/superUser/createTempPassword', {
      userId,
    });
  },
  releaseEmailSendConfirmationCode(userId) {
    return HttpService.post('/superUser/releaseEmailSendConfirmationCode', {
      userId,
    });
  },

  releaseEmailResendConfirmationCode(userId) {
    return HttpService.post('/superUser/releaseEmailResendConfirmationCode', {
      userId,
    });
  },

  releaseEmailConfirmCode(releaseEmailConfirmCode) {
    return HttpService.post('/superUser/releaseEmailConfirmCode', releaseEmailConfirmCode);
  },

  getReleaseEmail(userId) {
    return HttpService.get('/superUser/releaseEmail', { params: { userId } });
  },

  kickUser(userId) {
    return HttpService.post('/superUser/kickUser', {
      userId,
    });
  },

  toggleRevenueExcluded(userId) {
    return HttpService.post('/superUser/toggleRevenueExcluded', {
      userId,
    });
  },
  toggleBlocked(userId) {
    return HttpService.post('/superUser/toggleBlocked', {
      userId,
    });
  },

  recoverDeletedUser(userId) {
    return HttpService.post('/superUser/recoverDeletedUser', {
      userId,
    });
  },

  // ASAAS
  getAsaasCustomers() {
    return HttpService.get('/superUser/asaasCustomers');
  },

  updateAsaasCustomer(asaasInfo) {
    return HttpService.post('/superUser/asaasInfo', asaasInfo);
  },

  createAsaasSubscription(form) {
    return HttpService.post('/superUser/asaasSubscription', form);
  },

  upgradeAsaasSubscription(form) {
    return HttpService.post('/superUser/asaasSubscriptionUpgrade', form);
  },

  asaasSubscriptionProRataInfo(form) {
    return HttpService.post('/superUser/asaasSubscriptionProRataInfo', form);
  },

  createAsaasCharge(form) {
    return HttpService.post('/superUser/asaasCharge', form);
  },

  deleteAsaasCharge({ companyId, chargeId }) {
    return HttpService.delete('/superUser/asaasCharge', { params: { companyId, chargeId } });
  },

  deleteAsaasSubscription(companyId) {
    return HttpService.delete('/superUser/asaasSubscription', { params: { companyId } });
  },

  getAsaasPlan(maxNumberUsers) {
    return HttpService.get('/superUser/asaasPlan', { params: { maxNumberUsers } });
  },

  getAsaasSubscription(companyId) {
    return HttpService.get('/superUser/asaasSubscription', { params: { companyId } });
  },

  getLastPendingSubscriptionPayment(companyId) {
    return HttpService.get('/superUser/asaasSubscriptionLastPendingPayments', { params: { companyId } });
  },

  getAsaasPaymentHistory(asaasCustomerId, limit) {
    return HttpService.get('/superUser/asaasPaymentInfo', { params: { asaasCustomerId, limit } });
  },

  upgradeAsaasPayment(form) {
    return HttpService.post('/superUser/asaasPaymentUpdate', form);
  },

  updateAsaasSubscription(form) {
    return HttpService.post('/superUser/asaasSubscriptionUpdate', form);
  },

  getAsaasDiscount(companyId) {
    return HttpService.get('/superUser/asaasPaymentUpdate', { params: { companyId } });
  },

  deleteAsaas(companyId) {
    return HttpService.delete('/superUser/asaas', { params: { companyId } });
  },

  // ASAAS

  updateAccountType(accountTypeForm) {
    return HttpService.post('/superUser/accountType', accountTypeForm);
  },

  getUserListForCompany(companyId, includeDeleted) {
    return HttpService.get('/superUser/userListForCompany', { params: { companyId, includeDeleted } });
  },

  getLoginHistory(userId) {
    return HttpService.get('/superUser/loginHistory', { params: { userId } });
  },

  getResetPasswordHistory(userId) {
    return HttpService.get('/superUser/resetPasswordHistory', { params: { userId } });
  },

  searchUsers(nameOrPhoneOrEmail) {
    return HttpService.get('/superUser/searchUsers', { params: { nameOrPhoneOrEmail } });
  },
  stripeSearchUser(email) {
    return HttpService.get('/superUser/stripeSearchUser', { params: { email } });
  },
  stripeSearchUserDetails(customerId) {
    return HttpService.get('/superUser/stripeSearchUserDetails', { params: { customerId } });
  },

  getBoletoLink(companyId) {
    return HttpService.get('/superUser/boletoLinkForCompany', { params: { companyId } });
  },

  getAndroidSubscriptionInfo(androidReceiptHistoryId) {
    return HttpService.get('/superUser/androidSubscriptionInfo', { params: { androidReceiptHistoryId } });
  },

  getAndroidHistorySubscription(companyId) {
    return HttpService.get('/superUser/androidHistorySubscription', { params: { companyId } });
  },

  getAndroidSubscriptionDuplicated(androidReceiptHistoryId) {
    return HttpService.get('/superUser/androidSubscriptionDuplicated', {
      params: { androidReceiptHistoryId },
    });
  },

  getAndroidHistorySubscriptionDetail(androidReceiptHistoryId) {
    return HttpService.get('/superUser/androidHistorySubscriptionDetail', {
      params: { androidReceiptHistoryId },
    });
  },

  getAppleHistorySubscription(companyId) {
    return HttpService.get('/superUser/appleHistorySubscription', { params: { companyId } });
  },

  getAppleRefundHistory(originalTransactionId) {
    return HttpService.get('/superUser/appleRefundHistoryByOriginalTransactionId', {
      params: { originalTransactionId },
    });
  },

  getAppleHistorySubscriptionDetail(appleReceiptHistoryId) {
    return HttpService.get('/superUser/appleHistorySubscriptionDetail', {
      params: { appleReceiptHistoryId },
    });
  },
  getAppleSubscriptionDuplicated(appleReceiptHistoryId) {
    return HttpService.get('/superUser/appleSubscriptionDuplicated', {
      params: { appleReceiptHistoryId },
    });
  },
  getAppleSubscriptionDuplicatedByOriginalTransactionId(originalTransactionId) {
    return HttpService.get('/superUser/appleSubscriptionDuplicatedByOriginalTransactionId', {
      params: { originalTransactionId },
    });
  },

  getAppleSubscriptionInfo(appleReceiptHistoryId) {
    return HttpService.get('/superUser/appleSubscriptionInfo', { params: { appleReceiptHistoryId } });
  },

  getAppleSubscriptionInfoByReceiptId(receiptId) {
    return HttpService.get('/superUser/appleSubscriptionInfoByReceiptId', { params: { receiptId } });
  },

  getSherlockHolmesInfo() {
    return HttpService.get('/superUser/sherlockHolmes');
  },

  connectSherlockHolmesInfo(companyId) {
    return HttpService.put('/superUser/sherlockHolmes', {
      companyId,
    });
  },
  disconnectSherlockHolmesInfo() {
    return HttpService.delete('/superUser/sherlockHolmes');
  },

  sendMarketingEmail(companyId) {
    return HttpService.get('/superUser/sendMarketingEmail', { params: { companyId } });
  },

  getAppointmentReports({
    companyId,
    email,
    employeeId,
    clientId,
    startDate,
    endDate,
    deleted,
    byActivityDate,
    allRecords,
  }) {
    return HttpService.get('/superUser/appointmentReports', {
      params: {
        companyId,
        email,
        employeeId,
        clientId,
        startDate,
        endDate,
        deleted,
        byActivityDate,
        allRecords,
      },
    });
  },

  getAppointmentPaymentsReports({ companyId, date }) {
    return HttpService.get('/superUser/appointmentPaymentsReports', {
      params: {
        companyId,
        date,
      },
    });
  },

  getOriginalAppointmentOnline({ companyId, appointmentId }) {
    return HttpService.get('/superUser/originalAppointmentOnline', {
      params: {
        companyId,
        appointmentId,
      },
    });
  },

  getAppointmentReportsDeletedCustomer({ companyId, userId }) {
    return HttpService.get('/superUser/appointmentReportsDeletedCustomer', {
      params: { companyId, userId },
    });
  },

  getAppointmentWithPaymentsReports({ companyId, startDate, endDate }) {
    return HttpService.get('/superUser/appointmentWithPaymentsReports', {
      params: { companyId, startDate, endDate },
    });
  },

  getAllCustomers(companyId) {
    return HttpService.get('/superUser/allCustomers', {
      params: { companyId },
    });
  },

  importCustomers({ companyId, forms }) {
    return HttpService.post('/superUser/allCustomers', {
      companyId,
      forms,
    });
  },

  importIcsCalendar(companyId, file) {
    const formData = new FormData();
    formData.append('companyId', companyId);
    formData.append('file', file);
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return HttpService.post('/superUser/importIcsCalendar', formData, config);
  },

  getCompanyWorkSchedule(companyId) {
    return HttpService.get('/superUser/workScheduleForCompany', {
      params: { companyId },
    });
  },

  getCompanyWorkScheduleChangeLogs(workingScheduleId) {
    return HttpService.get('/superUser/workScheduleChangeLogForId', {
      params: { workingScheduleId },
    });
  },

  getEmployeeChangesCompanySubscriptionAuditLog(companyId, pageIndex) {
    return HttpService.get('/superUser/employeeChangesCompanySubscriptionAuditLog', {
      params: { companyId, pageIndex },
    });
  },

  getCompanyAccountAuditLog(companyId, pageIndex) {
    return HttpService.get('/superUser/companyAccountAuditLog', {
      params: { companyId, pageIndex },
    });
  },

  getCompanySubscriptionInfo(companyId) {
    return HttpService.get('/superUser/companySubscriptionInfo', {
      params: { companyId },
    });
  },

  updateCompanySubscriptionInfo(form) {
    return HttpService.post('/superUser/companySubscriptionInfo', form);
  },

  syncCompanySubscriptionInfo(form) {
    return HttpService.post('/superUser/companySubscriptionInfoSync', form);
  },

  updateCompanyOnlineSchedulingSettings(companyOnlineSchedulingSpecialForm) {
    return HttpService.post('/superUser/companyOnlineSchedulingSettings', companyOnlineSchedulingSpecialForm);
  },

  createTempAccessForCompany(companyId, extraParams, origin, notEligibleForIntroDiscount = false) {
    return HttpService.post('/superUser/tempAccessForCompany', {
      companyId,
      extraParams,
      origin,
      notEligibleForIntroDiscount,
    });
  },

  changeCompanyMaster({ companyId, masterUserId }) {
    return HttpService.post('/superUser/companyMaster', { companyId, masterUserId });
  },

  changeSubscriptionType({ companyId, subscriptionType, lastPaidAt }) {
    return HttpService.post('/superUser/subscriptionTypeSettings', {
      companyId,
      subscriptionType,
      lastPaidAt,
    });
  },

  returnCanceledSubscription(companyId) {
    return HttpService.get('/superUser/returnCanceledSubscription', { params: { companyId } });
  },

  containsStripeScheduledSubscription(companyId) {
    return HttpService.get('/superUser/containsStripeScheduledSubscription', { params: { companyId } });
  },

  createStripeScheduleSubscription({ companyId, planId, startDate, stripeDiscountId }) {
    return HttpService.post('/superUser/stripeScheduleSubscription', {
      companyId,
      planId,
      startDate,
      stripeDiscountId,
    });
  },
  getStripeScheduledSubscription(companyId) {
    return HttpService.get('/superUser/stripeScheduledSubscription', { params: { companyId } });
  },

  getStripeCharges(companyId) {
    return HttpService.get('/superUser/stripeCharges', { params: { companyId } });
  },
  getAllStripePaymentIntents(companyId) {
    return HttpService.get('/superUser/stripePaymentIntents', { params: { companyId } });
  },
  getStripeInvoices(companyId) {
    return HttpService.get('/superUser/stripeInvoices', { params: { companyId } });
  },

  getStripeDiscountsCupons({ companyId, planId }) {
    return HttpService.get('/superUser/stripeDiscountsCupons', { params: { companyId, planId } });
  },
  getStripeAllSubscription(companyId) {
    return HttpService.get('/superUser/stripeAllSubscriptions', { params: { companyId } });
  },
  getStripeSubscription(companyId) {
    return HttpService.get('/superUser/stripeSubscription', { params: { companyId } });
  },
  getStripeRefundInfo(companyId) {
    return HttpService.get('/superUser/stripeRefundInfo', { params: { companyId } });
  },
  refundStripeCharge({ companyId, chargeId, amount, description }) {
    return HttpService.post('/superUser/refundStripeCharge', { companyId, chargeId, amount, description });
  },
  cancelStripeInvoice({ companyId, invoiceId, observation }) {
    return HttpService.post('/superUser/cancelStripeInvoice', { companyId, invoiceId, observation });
  },
  cancelStripeScheduledSubscription({ companyId }) {
    return HttpService.delete('/superUser/stripeSubscriptionScheduled', { params: { companyId } });
  },
  payStripeInvoice({ companyId, invoiceId }) {
    return HttpService.post('/superUser/payStripeInvoice', { companyId, invoiceId });
  },
  detailStripeInvoice({ companyId, invoiceId }) {
    return HttpService.post('/superUser/detailStripeInvoice', { companyId, invoiceId });
  },
  stripePaymentIntent({ companyId, paymentIntentId }) {
    return HttpService.post('/superUser/stripePaymentIntent', { companyId, paymentIntentId });
  },
  stripeChargesByPaymentIntent({ companyId, paymentIntentId }) {
    return HttpService.post('/superUser/stripeChargesByPaymentIntent', { companyId, paymentIntentId });
  },
  getStripeDisputeDocAppointmentReport({ companyId, chargeId }) {
    return HttpService.get('/superUser/stripeDisputeDocAppointmentReport', {
      params: { companyId, chargeId },
    });
  },
  getStripeDisputeDocSubscriptionReport({ companyId }) {
    return HttpService.get('/superUser/stripeDisputeDocSubscriptionReport', { params: { companyId } });
  },
  getStripeDisputeDocReceiptUrl({ companyId, chargeId }) {
    return HttpService.get('/superUser/stripeDisputeDocsReceiptUrl', { params: { companyId, chargeId } });
  },
  deleteStripeSubscription(companyId, chargeId, refundValue) {
    return HttpService.delete('/superUser/stripeSubscription', {
      params: { companyId, chargeId, refundValue },
    });
  },
  getStripePlan(maxNumberUsers) {
    return HttpService.get('/superUser/stripePlan', { params: { maxNumberUsers } });
  },
  stripeSubscriptionProRataInfo(form) {
    return HttpService.post('/superUser/stripeSubscriptionProRataInfo', form);
  },
  upgradeStripeSubscription(form) {
    return HttpService.post('/superUser/stripeSubscription', form);
  },

  scheduleStripeSubscription({ companyId, priceCode, startDate, stripeDiscountId }) {
    return HttpService.post('/superUser/scheduleStripeSubscription', {
      companyId,
      priceCode,
      startDate,
      stripeDiscountId,
    });
  },

  cancelAndroidSubscription(companyId) {
    return HttpService.post('/superUser/cancelAndroidSubscription', {
      companyId,
    });
  },

  sendPushNotification({ companyId, notificationType, silent }) {
    return HttpService.post('/superUser/pushNotification', {
      companyId,
      notificationType,
      silent,
    });
  },

  changeStripeAsaasSubscriptionSettings({
    companyId,
    asaasCustomerId,
    asaasSubscriptionId,
    stripeCustomerId,
    stripeSubscriptionId,
  }) {
    return HttpService.post('/superUser/stripeAsaasSubscriptionSettings', {
      companyId,
      asaasCustomerId,
      asaasSubscriptionId,
      stripeCustomerId,
      stripeSubscriptionId,
    });
  },

  getCompanyOnlineSchedulingInfo(companyId) {
    return HttpService.get('/superUser/companyOnlineSchedulingAllInfo', {
      params: { companyId },
    });
  },

  getCompanyOnlineSchedulingServices(companyId) {
    return HttpService.get('/superUser/companyOnlineSchedulingServices', {
      params: { companyId },
    });
  },

  getCompanyOnlineSchedulingProfessionals(companyId) {
    return HttpService.get('/superUser/companyOnlineSchedulingProfessionals', {
      params: { companyId },
    });
  },

  deleteCompanyOnlineSchedulingSpecificServiceProfessionals(companyId) {
    return HttpService.delete('/superUser/companyOnlineSchedulingSpecificServiceProfessionals', {
      params: { companyId },
    });
  },

  getCompanyOnlineSchedulingExpedient(companyId) {
    return HttpService.get('/superUser/companyOnlineSchedulingExpedient', {
      params: { companyId },
    });
  },
  getCompanyOnlineSchedulingUnavailablePeriods(companyId) {
    return HttpService.get('/superUser/companyOnlineSchedulingUnavailablePeriods', {
      params: { companyId },
    });
  },
  getCompanyOnlineSchedulingBlockedCustomers(companyId) {
    return HttpService.get('/superUser/companyOnlineSchedulingBlockedCustomers', {
      params: { companyId },
    });
  },
  createCompanyOnlineSchedulingBlockedCustomers(blockedCustomer) {
    return HttpService.post('/superUser/companyOnlineSchedulingBlockedCustomers', blockedCustomer);
  },
  updateCompanyOnlineSchedulingBlockedCustomers(blockedCustomer) {
    return HttpService.put('/superUser/companyOnlineSchedulingBlockedCustomers', blockedCustomer);
  },
  deleteCompanyOnlineSchedulingBlockedCustomers(blockedCustomerId) {
    return HttpService.delete('/superUser/companyOnlineSchedulingBlockedCustomers', {
      params: { blockedCustomerId },
    });
  },

  getCompanyOnlineSchedulingAnticipatedPayment(companyId) {
    return HttpService.get('/superUser/companyOnlineSchedulingAnticipatedPayment', {
      params: { companyId },
    });
  },
  updateCompanyOnlineSchedulingAnticipatedPayment(form) {
    return HttpService.put('/superUser/companyOnlineSchedulingAnticipatedPayment', form);
  },

  lookupAppleSubscription(orderId) {
    return HttpService.get('/superUser/appleSubscriptionLookup', {
      params: { orderId },
    });
  },

  getAppleTransactionHistory(transactionId) {
    return HttpService.get('/superUser/appleTransactionHistory', {
      params: { transactionId },
    });
  },

  getCompanyInfoBySlug(slug) {
    return HttpService.get('/superUser/companyInfoBySlug', {
      params: { slug },
    });
  },

  changePageBioSettings({
    companyId,
    name,
    headerText,
    whatsNumber,
    whatsTitle,
    whatsMessage,
    whatsNumber2,
    whatsTitle2,
    whatsMessage2,
    whatsNumber3,
    whatsTitle3,
    whatsMessage3,
    instagramUser,
    instagramTitle,
    instagramUser2,
    instagramTitle2,
    address,
    address2,
    externalLinkTitle,
    externalLinkUrl,
  }) {
    return HttpService.post('/superUser/pageBioSettings', {
      companyId,
      name,
      headerText,
      whatsNumber,
      whatsTitle,
      whatsMessage,
      whatsNumber2,
      whatsTitle2,
      whatsMessage2,
      whatsNumber3,
      whatsTitle3,
      whatsMessage3,
      instagramUser,
      instagramTitle,
      instagramUser2,
      instagramTitle2,
      address,
      address2,
      externalLinkTitle,
      externalLinkUrl,
    });
  },

  findCompaniesToTransferSubscription(emailOrigin, emailDestination) {
    return HttpService.get('/superUser/transferSubscription', {
      params: { emailOrigin, emailDestination },
    });
  },

  transferSubscription({ originCompanyId, destinationCompanyId }) {
    return HttpService.post('/superUser/transferSubscription', { originCompanyId, destinationCompanyId });
  },
};

export default SuperUserService;
