import { Box, Button, Chip, CircularProgress, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import SuperUserService from '../../../../services/SuperUserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import showNotification from '../../../../commons/helpers/showNotification';

// COMPONENT
function CompanyOnlineSchedulingSettingsProfessionals({
  selectedCompany,
  open,
  companyOnlineSchedulingProfessionals,
  setCompanyOnlineSchedulingProfessionals,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (open && !companyOnlineSchedulingProfessionals) {
      setIsLoading(true);
      SuperUserService.getCompanyOnlineSchedulingProfessionals(selectedCompany.company.id)
        .then((resp) => {
          setCompanyOnlineSchedulingProfessionals(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, selectedCompany, companyOnlineSchedulingProfessionals, setCompanyOnlineSchedulingProfessionals]);

  return (
    <>
      {isLoading ? (
        <Box sx={{ mt: 5, mb: 5, p: 5, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="inherit" size={20} />
        </Box>
      ) : (
        <List>
          {companyOnlineSchedulingProfessionals?.some((p) => p.servicePerEmployeeMode) && (
            <Button
              variant="outlined"
              sx={{ mr: 1, mb: 1 }}
              disabled={isWorking}
              onClick={async () => {
                const answer = window.confirm(
                  'Você tem certeza que deseja remover a configuração de serviço especifico por funcionario?',
                );
                if (answer) {
                  const answer = window.confirm('Você tem certeza mesmo?');

                  if (answer) {
                    try {
                      setIsWorking(true);
                      await SuperUserService.deleteCompanyOnlineSchedulingSpecificServiceProfessionals(
                        selectedCompany.company.id,
                      );
                      const updatedResp = await SuperUserService.getCompanyOnlineSchedulingProfessionals(
                        selectedCompany.company.id,
                      );
                      setCompanyOnlineSchedulingProfessionals(updatedResp);

                      showNotification('success', 'Removido com sucesso.');
                    } finally {
                      setIsWorking(false);
                    }
                  }
                }
              }}
              color="error"
              startIcon={
                isWorking ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <FontAwesomeIcon icon="trash" color="explicitError" />
                )
              }
            >
              Deletar Serviço Especifico por Funcionário
            </Button>
          )}
          {companyOnlineSchedulingProfessionals &&
            companyOnlineSchedulingProfessionals.map((employee, index) => (
              <ListItem key={index}>
                <Box sx={{ my: '6px' }}>
                  <Typography>{employee.name}</Typography>
                  <Box sx={{ fontSize: '0.875rem' }}>
                    Serviços:{' '}
                    {
                      <Box component="span" color={theme.palette.text.primary}>
                        {employee.servicesEnabled.length === 0
                          ? 'Todos serviços disponíveis'
                          : employee.servicesEnabled.map((service) => service.name).join(', ')}
                      </Box>
                    }
                    <Chip
                      sx={{ ml: 1.5 }}
                      variant={employee.onlineSchedulingEnabled ? 'contained' : 'outlined'}
                      size="small"
                      color={employee.onlineSchedulingEnabled ? 'success' : 'error'}
                      label={employee.onlineSchedulingEnabled ? 'Ativado' : 'Desativado'}
                    />
                  </Box>
                </Box>
              </ListItem>
            ))}
        </List>
      )}
    </>
  );
}

export default CompanyOnlineSchedulingSettingsProfessionals;
