import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import FontAwesomeDoubleIconStatus from '../../components/icon/FontAwesomeDoubleIconStatus';

const ActionButton = {
  BIO: 0,
  AGENDA: 1,
  USUARIOS: 2,
  HISTORICO_LOGIN: 3,
  LOGS_REC_SENHA: 4,
  CREATE_TEMP_SENHA: 5,
  LINK_ATIVACAO: 6,
  LINK_UPGRADE: 7,
  ACCOUNT_DELETE: 8,
  ACCOUNT_RESET: 9,
  SEND_EMAIL: 10,
  EXPEDIENTE: 11,
  EXPORTAR_AGENDA: 12,
  EXPORTAR_AGENDA_COM_PAGAMENTOS: 13,
  IMPORTAR_CLIENTES: 14,
  EXPORTAR_CLIENTES: 15,
  DELETAR_CLIENTES: 16,
  ENVIAR_NOTIFICACAO: 17,
  PAINEL_ASSINATURA: 18,
  MUDAR_VENCIMENTO_STRIPE: 19,
  IMPORTAR_AGENDA: 20,
  LINK_ATIVACAO_ASAAS: 21,
  EXPORTAR_AGENDAMENTO_PAGAMENTO: 22,
};

function MainButtons({ onClickButton, selectedCompany }) {
  const buttons = [
    {
      type: ActionButton.BIO,
      title: 'Página de Bio',
      iconName: 'address-card',
      disabled: !selectedCompany.companyPageBio || !selectedCompany.companyPageBio.slug,
      hidden: !selectedCompany.loadedOnlineLinkInfo,
    },
    {
      type: ActionButton.AGENDA,
      title: 'Agenda Online',
      iconName: 'globe',
      disabled: !selectedCompany.companyOnlineScheduling,
      hidden: !selectedCompany.loadedOnlineLinkInfo,
    },
    {
      type: ActionButton.SEND_EMAIL,
      title: 'Enviar email',
      modalConfirmMessage: 'Você tem certeza que deseja enviar um email de teste?',
      iconName: 'envelope',
      disabled: false,
    },
    {
      type: ActionButton.EXPEDIENTE,
      title: 'Expediente',
      iconName: 'clock',
      toolTipMessage: 'Visualizar expediente da empresa',
      disabled: false,
    },
    {
      type: ActionButton.EXPORTAR_AGENDA,
      title: 'Consultar Agenda',
      iconName: 'calendar',
      secondIconName: 'magnifying-glass',
      iconColor: 'primary',
      status2: 'info',
      toolTipMessage: 'Consultar agendamentos',
      disabled: false,
    },
    {
      type: ActionButton.EXPORTAR_AGENDAMENTO_PAGAMENTO,
      title: 'Consultar Agen. vs Pagamento',
      iconName: 'calendar',
      secondIconName: 'magnifying-glass',
      iconColor: 'primary',
      status2: 'info',
      toolTipMessage: 'Consultar Agen. vs Pagamento',
      disabled: false,
    },
    // {
    //   type: ActionButton.EXPORTAR_AGENDA_COM_PAGAMENTOS,
    //   title: 'Exportar Com Pagamentos',
    //   iconName: 'calendar',
    //   secondIconName: 'search',
    //   iconColor: 'primary',
    //   status2: 'info',
    //   toolTipMessage: 'Exportar agendamentos com pagamentos',
    //   disabled: false,
    // },
    {
      type: ActionButton.EXPORTAR_CLIENTES,
      title: 'Exportar Clientes',
      modalConfirmMessage: 'Você tem certeza que deseja exportar a base de clientes?',
      iconName: 'user',
      secondIconName: 'download',
      iconColor: 'primary',
      status2: 'info',
      disabled: false,
    },
    {
      type: ActionButton.DELETAR_CLIENTES,
      title: 'Deletar Clientes',
      modalConfirmMessage:
        'Você tem certeza que deseja deletar todos os clientes sem atendimento, comanda, vendas?',
      textConfirm: 'DELETAR CLIENTES',
      iconName: 'user',
      secondIconName: 'trash',
      iconColor: 'explicitError',
      toolTipMessage: 'Deletar clientes sem dados',
      disabled: false,
    },
    {
      type: ActionButton.ENVIAR_NOTIFICACAO,
      title: 'Enviar notificação',
      iconName: 'paper-plane',
      secondIconName: 'bell',
      iconColor: 'primary',
      disabled: false,
    },
    {
      type: ActionButton.PAINEL_ASSINATURA,
      title: 'Painel Assinatura',
      iconName: 'file-invoice-dollar',
      secondIconName: 'cog',
      toolTipMessage: 'Abrir painel de assinatura',
      iconColor: 'primary',
      disabled: false,
    },
    {
      type: ActionButton.MUDAR_VENCIMENTO_STRIPE,
      title: 'Mudar Vencimento',
      modalConfirmMessage:
        'Você tem certeza que deseja gerar o link de MUDANÇA DE VENCIMENTO? Ele dura 48hs e se vc ja tiver gerado outro para este usuario, o link antigo ira se invalidar.',
      iconName: ['fab', 'stripe'],
      secondIconName: 'calendar',
      iconColor: 'info',
      toolTipMessage: 'Mudar data Vencimento Stripe',
      disabled: false,
    },
  ];

  return (
    <Grid container rowGap={1}>
      {buttons
        .filter((p) => !p.hidden)
        .map((button, index) => (
          <Box
            key={index}
            sx={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip
              title={button.toolTipMessage ? button.toolTipMessage : ''}
              disableHoverListener={button.toolTipMessage ? false : true}
            >
              <Button
                variant="outlined"
                onClick={() => onClickButton(button)}
                disabled={button.disabled}
                fullWidth
                sx={{ justifyContent: 'start', pt: button.secondIconName ? 2 : '' }}
                startIcon={
                  button.secondIconName ? (
                    <FontAwesomeDoubleIconStatus
                      icon1={button.iconName}
                      icon2={button.secondIconName}
                      status={button.iconColor}
                      status2={button.status2}
                    />
                  ) : (
                    <FontAwesomeIcon icon={button.iconName} color={button.iconColor} />
                  )
                }
                color={button.iconColor}
              >
                <Box component="span" sx={{ ml: button.secondIconName ? 1.25 : '0' }}>
                  {button.title}
                </Box>
              </Button>
            </Tooltip>
          </Box>
        ))}
    </Grid>
  );
}

export { MainButtons, ActionButton };
