import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

// COMPONENT
function AdmDashboardPage() {
  return (
    <>
      <Typography gutterBottom>Relatórios</Typography>

      <Box display="flex" flexDirection="row" columnGap={3}>
        <Button variant="contained" component={Link} to="new-accounts">
          Relatório Novos Cadastros
        </Button>
        <Button variant="contained" component={Link} to="subs">
          Relatório Subs
        </Button>
        <Button variant="contained" component={Link} to="subs-by-number-of-users">
          Subs por Number Of Users
        </Button>
      </Box>
    </>
  );
}

export default AdmDashboardPage;
