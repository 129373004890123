import { useState } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  Chip,
  Select,
  MenuItem,
  Alert,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  FormTextField,
  FormDateField,
  FormNumberFormatField,
  FormCurrencyField,
  FormSelect,
  FormTimeField,
  FormCpfField,
  FormPhoneField,
  FormBooleanRadioGroup,
  FormCheckboxGroup,
  FormRadioGroup,
} from '../../components/forms/FormikFields';
import { Formik, Form } from 'formik';
import yup from '../../commons/validators/customYup';
import HintText from '../../components/labels/HintText';
import LabelValueDisplay from '../../components/labels/LabelValueDisplay';
import LabelStatus from '../../components/labels/LabelStatus';
import HighLabelStatus from '../../components/labels/HighLabelStatus';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import FontAwesomeDoubleIconStatus from '../../components/icon/FontAwesomeDoubleIconStatus';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import MockAutocomplete from '../../components/typeahead/MockAutocomplete';
import showNotification from '../../commons/helpers/showNotification';
import NumberUtils from '../../commons/utils/numberUtils';
import CompanyAutocomplete from '../../components/typeahead/CompanyAutocomplete';

const schema = yup.object().shape({
  firstName: yup.string().min(2).max(50).required(),
  lastName: yup.string().min(2).max(50).required(),
  email: yup.string().required(),
  profession: yup.number().required(),
});

const OPTIONS = [
  { id: 1, name: 'Senior Fullstack Engineer' },
  { id: 2, name: 'Senior Backend Engineer' },
  { id: 3, name: 'Senior Frontend Engineer' },
  { id: 4, name: 'Senior Tech Leader' },
  { id: 5, name: 'Junior Developer' },
  { id: 6, name: 'Pleno Developer' },
];

const DEFAULT_INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  birthDate: null,
  numberOfChildren: null,
  salary: null,
  profession: '',
  isSportsman: false,
  sports: [],
  phoneBrand: 1,
};

// COMPONENT
function ComponentExamples() {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedMockObject, setSelectedMockObject] = useState('');
  const [notificationType, setNotificationType] = useState('info');
  const [selectedCompany, setSelectedCompany] = useState();

  const myHandleSubmit = (values, actions) => {
    actions.setSubmitting(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Typography>Examples</Typography>
      </Grid>

      <Grid item sm={12}>
        <Alert severity="warning">
          <b>IMPORTANTE:</b> Antes de mais nada, configure no seu <Chip label="VSCode" /> as seguintes
          extensions:
          <br />
          <Chip
            label="Prettier - Usado para formatação e padronização de código"
            color="primary"
            sx={{ mb: 1 }}
          />
          <br />
          <Chip
            label="ESLint - Verificar de syntax estatico para pegar erros e má praticas"
            color="primary"
            sx={{ mb: 1 }}
          />
          <br />
          <Chip label="EditorConfig - Padronização de espaçamento" color="primary" sx={{ mb: 1 }} />
          <br />
        </Alert>
      </Grid>

      <Grid item sm={12}>
        <Typography component="span">
          Declare a rota em <Chip label="routes/index.jsx" />
        </Typography>
        <br />
        <Typography component="span">
          Rotas acessiveis via menu configure em <Chip label="routes/menuItems.js" />
        </Typography>
        <br />
        <br />
        <Divider />
      </Grid>

      {/* NumberUtils */}
      <Grid item sm={12}>
        <Typography>NumberUtils</Typography>

        <Typography variant="body2">{NumberUtils.toCurrency(10000)}</Typography>
        <Typography variant="body2">{NumberUtils.toDecimals(10.53, 2)}</Typography>
        <Typography variant="body2">{NumberUtils.formatAsPercent(10.2, 1)}</Typography>

        {/* <Confirm */}
        <br />
        <Divider />
      </Grid>

      {/* NOTIFICATIONS */}
      <Grid item sm={12}>
        <Typography>Notifications</Typography>

        <Select
          placeholder="Selecione o tipo de notificação"
          value={notificationType}
          onChange={(e) => {
            setNotificationType(e.target.value);
          }}
        >
          <MenuItem value="info">info</MenuItem>
          <MenuItem value="success">success</MenuItem>
          <MenuItem value="warning">warning</MenuItem>
          <MenuItem value="error">error</MenuItem>
        </Select>
        <Button
          onClick={() => {
            showNotification(notificationType, 'Mensagem vai aqui!!!');
          }}
        >
          Show
        </Button>

        {/* <Confirm */}
        <br />
        <Divider />
      </Grid>

      {/* BUTTONS */}
      <Grid item sm={12}>
        <Typography>Buttons</Typography>
        <Button color="primary" variant="contained">
          Button with status color
        </Button>
        <Button color="info" variant="contained">
          Button with status color
        </Button>
        <Button color="success" variant="contained">
          Button with status color
        </Button>
        <Button color="warning" variant="contained">
          Button with status color
        </Button>
        <Button color="error" variant="contained">
          Button with status color
        </Button>
        <br />
        <Button color="primary" variant="outlined">
          Button with status color
        </Button>
        <Button color="info" variant="outlined">
          Button with status color
        </Button>
        <Button color="success" variant="outlined">
          Button with status color
        </Button>
        <Button color="warning" variant="outlined">
          Button with status color
        </Button>
        <Button color="error" variant="outlined">
          Button with status color
        </Button>

        <br />
        <Divider />
      </Grid>

      {/* LABELS */}
      <Grid item sm={12}>
        <Typography variant="h4">Labels</Typography>
        <HighLabelStatus status="primary">HighLabel example</HighLabelStatus>
        <HighLabelStatus status="info">HighLabel example</HighLabelStatus>
        <HighLabelStatus status="success">HighLabel example</HighLabelStatus>
        <HighLabelStatus status="warning">HighLabel example</HighLabelStatus>
        <HighLabelStatus status="error">HighLabel example</HighLabelStatus>
        <br />
        <LabelStatus status="primary" component="span">
          Status label example{' '}
        </LabelStatus>
        <LabelStatus status="info" component="span">
          Status label example{' '}
        </LabelStatus>
        <LabelStatus status="success" component="span">
          Status label example{' '}
        </LabelStatus>
        <LabelStatus status="warning" component="span">
          Status label example{' '}
        </LabelStatus>
        <LabelStatus status="error" component="span">
          Status label example
        </LabelStatus>
        <br />
        <HintText>Hint Text Example</HintText>
        <LabelValueDisplay label="Label with detail example" value="Detail goes here" />
        <br />
        <Divider />
      </Grid>

      {/* ICONS */}
      <Grid item sm={12}>
        <Typography>Icons</Typography>

        <Typography>Basic icon manual color</Typography>
        <FontAwesomeIcon icon="clock" size="lg" fixedWidth />

        <Typography>Icon with color status</Typography>
        <FontAwesomeIconStatus status="primary" icon="clock" size="lg" fixedWidth />

        <Typography sx={{ mb: 2 }}>Double icon status</Typography>
        <div>
          <FontAwesomeDoubleIconStatus icon1="calendar" icon2="download" status="primary" />
        </div>

        <br />
        <Divider />
      </Grid>

      {/* MODAL */}
      <Grid item sm={12}>
        <Typography>Modals</Typography>

        <Button
          onClick={() => {
            setIsVisible(true);
          }}
        >
          Confirmation Dialog
        </Button>
        <ConfirmationDialog
          title="Cancelamento de assinatura"
          message="Você tem certeza que deseja cancelar a assinatura?"
          open={isVisible}
          onClose={() => {
            setIsVisible(false);
          }}
          onConfirm={() => {
            window.alert('Clicked on Confirm');
            setIsVisible(false);
          }}
          onCancel={() => {
            window.alert('Clicked on Cancel');
          }}
        />
        {/* <Confirm */}
        <br />
        <Divider />
      </Grid>

      {/* AutoComplete Server-Side */}
      <Grid item sm={12}>
        <Typography>AutoCompletes</Typography>
        <MockAutocomplete
          value={selectedMockObject}
          onChange={(newSelected) => {
            setSelectedMockObject(newSelected);
          }}
          getSuggestionLabel={(object) => {
            if (!object) {
              return '';
            }
            return object.name;
          }}
          label="Search by Name"
          placeholder="Search for customers by name"
        />
        <br />

        <CompanyAutocomplete
          value={selectedCompany}
          onChange={(newSelected) => {
            setSelectedCompany(newSelected);
          }}
          getSuggestionLabel={(object) => {
            if (!object) {
              return '';
            }
            return object.name;
          }}
          label="Empresa"
          placeholder="Procure pelo nome ou email (min 3 caracteres)"
        />
        <Divider />
      </Grid>

      {/* FORMS */}
      <Grid item sm={12}>
        <Typography>Form</Typography>

        <Card>
          <Formik
            enableReinitialize
            initialValues={DEFAULT_INITIAL_VALUES}
            validationSchema={schema}
            onSubmit={myHandleSubmit}
          >
            {({ dirty, isSubmitting }) => (
              <Form>
                <CardContent>
                  <FormTextField name="firstName" type="text" label="Primeiro Nome" fullWidth />
                  <FormTextField name="lastName" type="text" label="Ultimo Nome" fullWidth />
                  <FormTextField name="email" type="email" label="Email" fullWidth />
                  <FormDateField name="birthDate" label="Data de Nascimento" fullWidth />
                  <FormNumberFormatField name="numberOfChildren" label="Numero de Filhos" fullWidth />
                  <FormCurrencyField name="salary" label="Salario" fullWidth />
                  <FormSelect name="profession" label="Profissão" fullWidth dataSource={OPTIONS} />

                  <FormPhoneField name="phone" label="Telefone" fullWidth />
                  <FormCpfField name="cpf" label="CPF" fullWidth />

                  <FormTimeField fullWidth label="Horario Preferido do Dia" name="preferredTime" />
                  <FormBooleanRadioGroup
                    labelFalse="Sedentário"
                    labelTrue="Pratica Esporte"
                    name="isSportsman"
                    row
                  />
                  <br />
                  <FormCheckboxGroup
                    label="Esportes que pratica"
                    name="sports"
                    dataSource={[
                      {
                        id: 1,
                        name: 'Corrida',
                      },
                      { id: 2, name: 'Mountain Bike' },
                      { id: 3, name: 'Natação' },
                    ]}
                    row
                  />
                  <br />
                  <FormRadioGroup
                    label="Marca do Telefone"
                    name="phoneBrand"
                    dataSource={[
                      {
                        id: 1,
                        name: 'Apple',
                      },
                      { id: 2, name: 'Samsung' },
                    ]}
                    row
                  />
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                    sx={{ ml: 1 }}
                  >
                    Submit
                  </Button>
                </CardActions>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ComponentExamples;
