import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import showNotification from '../../../commons/helpers/showNotification';
import monthUtils from '../../../commons/utils/monthUtils';
import NumberUtils from '../../../commons/utils/numberUtils';
import FontAwesomeDoubleIconStatus from '../../../components/icon/FontAwesomeDoubleIconStatus';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import HintText from '../../../components/labels/HintText';
import SuperUserService from '../../../services/SuperUserService';
import { failures, statusSubscription, stripePeriodType } from './stripeHelpers';
import StripePaymentHistoryTable from './StripePaymentHistoryTable';
import StripeUpgradeSubscriptionModal from './StripeUpgradeSubscriptionModal';
import ConfirmationDeleteSubscriptionDialog from './ConfirmationDeleteSubscriptionDialog';
import StripeCreateScheduleSubscription from './StripeCreateScheduleSubscription';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const dot = (
  <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    •
  </Box>
);

const dotCard = (
  <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    ••••
  </Box>
);

// COMPONENT
function StripeAccountInfoModal({
  selectedCompany,
  open,
  onClose,
  onSave,
  openConfirmationModal,
  setManageStripeInfoModalOpen,
  setModalConfirm,
}) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [modalUpgradeSubOpen, setModalUpgradeSubOpen] = useState(false);
  const [modalScheduleSubscriptionOpen, setModalScheduleSubscriptionOpen] = useState(false);
  const [modalConfirmarionDeleteSubscriptionOpen, setModalConfirmarionDeleteSubscriptionOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
  const [isLoadingSubscriptionScheduled, setIsLoadingSubscriptionScheduled] = useState(false);
  const [stripeSubscriptionDetails, setStripeSubscriptionDetails] = useState();
  const [stripeSubscriptionScheduledDetails, setStripeSubscriptioShedulednDetails] = useState();
  const [stripePaymentIntents, setStripePaymentIntents] = useState();

  useEffect(() => {
    if (open) {
      setValue(0);
      setStripeSubscriptionDetails(null);
      setStripeSubscriptioShedulednDetails(null);
      setStripePaymentIntents(null);
      setIsLoadingSubscription(true);
      setIsLoadingSubscriptionScheduled(true);
      SuperUserService.getStripeAllSubscription(selectedCompany.id)
        .then((stripeAllSubscription) => {
          setStripeSubscriptionDetails(stripeAllSubscription);
        })
        .finally(() => {
          setIsLoadingSubscription(false);
        });

      SuperUserService.getStripeScheduledSubscription(selectedCompany.id)
        .then((stripeSubscriptionSheduledInfo) => {
          setStripeSubscriptioShedulednDetails(stripeSubscriptionSheduledInfo);
        })
        .finally(() => {
          setIsLoadingSubscriptionScheduled(false);
        });
    }
  }, [open, selectedCompany]);

  const handleChange = (event, tab) => {
    setValue(tab);
  };

  const handleStripeCancelCharge = (invoice) => {
    openConfirmationModal(
      'Anular cobrança do Stripe',
      'Ao confirmar, a cobrança será anulada. Deseja anular a cobrança do Stripe?',
      (params) => {
        handleCancelCharge(invoice, params);
      },
      '',
      'Qual o motivo do cancelamento?',
    );
  };

  const handleCancelCharge = (invoice, params) => {
    setIsLoading(true);
    SuperUserService.cancelStripeInvoice({
      companyId: selectedCompany.id,
      invoiceId: invoice.id,
      observation: params.observation,
    })
      .then(() => {
        setStripePaymentIntents(
          stripePaymentIntents.map((paymentIntent) =>
            paymentIntent.invoice?.id === invoice.id
              ? {
                  ...paymentIntent,
                  invoice: {
                    ...paymentIntent.invoice,
                    status: 'void',
                  },
                }
              : paymentIntent,
          ),
        );
        showNotification('success', 'Fatura anulada com sucesso.');
      })
      .finally(() => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        setIsLoading(false);
      });
  };

  const handleStripeRefundCharge = (invoice) => {
    let amount;
    let partial;
    if (invoice.refunds?.length > 0) {
      partial = invoice.refunds.map((refund) => refund.amount).reduce((a, b) => a + b);
      amount = invoice.amount - partial;
    } else {
      amount = invoice.amount;
    }

    openConfirmationModal(
      'Reembolsar cobrança do Stripe',
      'Ao confirmar, a cobrança será reembolsada. Deseja reembolsar a cobrança do Stripe?',
      (values) => {
        handleRefundCharge(invoice, values);
      },
      '',
      'Informe um motivo para o reembolso .',
      amount,
      partial && `Já foi feito reembolso parcial de ${NumberUtils.toCurrency(partial)}`,
    );
  };
  const handleRefundCharge = (invoice, values) => {
    setIsLoading(true);

    SuperUserService.refundStripeCharge({
      companyId: selectedCompany.id,
      chargeId: invoice.chargeId,
      amount: values.amount,
      description: values.observation,
    })
      .then((result) => {
        setStripePaymentIntents(
          stripePaymentIntents.map((paymentIntent) =>
            paymentIntent.invoice?.chargeId === invoice.chargeId
              ? {
                  ...paymentIntent,
                  invoice: {
                    ...paymentIntent.invoice,
                    refunded: result.refunded,
                    refunds: result.refunds,
                  },
                }
              : paymentIntent,
          ),
        );
        showNotification('success', 'Fatura reembolsada.');
      })
      .finally(() => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        setIsLoading(false);
      });
  };

  const handleStripeDeleteSubscription = () => {
    setModalConfirmarionDeleteSubscriptionOpen(true);
  };

  const handleStripeDeleteScheduledSubscription = () => {
    openConfirmationModal(
      'Cancelar assinatura agendada?',
      'Ao confirmar, será feito o cancelamento da assinatura agendada. Deseja efetuar o agendamento do Stripe?',
      () => {
        handleCancelStripeScheduledSubscription();
      },
      '',
      '',
    );
  };

  const handleScheduleSubscription = () => {
    setModalScheduleSubscriptionOpen(true);
  };

  const handleStripeReturnCanceledSubscription = () => {
    openConfirmationModal(
      'Deseja restaurar a assinatura?',
      'Ao confirmar, a assinatura será restaurada. Deseja restaurar a assinatura no Stripe?',
      () => {
        returnCanceledSubscription();
      },
      '',
      '',
    );
  };

  const returnCanceledSubscription = () => {
    setIsLoading(true);
    SuperUserService.returnCanceledSubscription(selectedCompany.id)
      .then((result) => {
        showNotification('success', 'Assinatura restaurada com sucesso.');
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleCancelStripeScheduledSubscription = () => {
    SuperUserService.cancelStripeScheduledSubscription({
      companyId: selectedCompany.id,
    })
      .then((result) => {
        onSave({ ...result });
        showNotification('success', 'Agendamento cancelado com sucesso.');
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleStripePayInvoice = (invoice) => {
    openConfirmationModal(
      'Efetuar cobrança do Stripe',
      'Ao confirmar, será feita uma tentativa de cobrança no cartão padrão. Deseja efetuar a cobrança do Stripe?',
      (observation) => {
        handlePayInvoice(invoice, observation);
      },
      '',
      '',
    );
  };
  const handlePayInvoice = (invoice) => {
    setIsLoading(true);
    setStripePaymentIntents(
      stripePaymentIntents.map((paymentIntent) =>
        paymentIntent.invoice?.id === invoice.id
          ? {
              ...paymentIntent,
              invoice: {
                ...paymentIntent.invoice,
                status: 'trying',
              },
            }
          : paymentIntent,
      ),
    );
    setModalConfirm((prev) => ({ ...prev, open: false }));
    SuperUserService.payStripeInvoice({ companyId: selectedCompany.id, invoiceId: invoice.id })
      .then((payStripe) => {
        if (payStripe === 'success') {
          setStripePaymentIntents(
            stripePaymentIntents.map((paymentIntent) =>
              paymentIntent.invoice?.id === invoice.id
                ? {
                    ...paymentIntent,
                    invoice: {
                      ...paymentIntent.invoice,
                      status: 'paid',
                      statusCharge: 'succeeded',
                    },
                  }
                : paymentIntent,
            ),
          );
          showNotification('success', 'Pagamento efetuado');
        } else {
          setStripePaymentIntents(
            stripePaymentIntents.map((paymentIntent) =>
              paymentIntent.invoice?.id === invoice.id
                ? {
                    ...paymentIntent,
                    invoice: {
                      ...paymentIntent.invoice,
                      status: 'open',
                      statusCharge: 'failed',
                    },
                  }
                : paymentIntent,
            ),
          );
          showNotification('error', 'Ocorreu um erro: ' + failures[payStripe]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      onClose={() => {
        setStripePaymentIntents(null);
        onClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <ConfirmationDeleteSubscriptionDialog
        open={modalConfirmarionDeleteSubscriptionOpen}
        selectedCompany={selectedCompany}
        stripeSubscriptionDetails={stripeSubscriptionDetails}
        setStripeSubscriptionDetails={setStripeSubscriptionDetails}
        onSave={onSave}
        onClose={() => {
          setModalConfirmarionDeleteSubscriptionOpen(false);
        }}
      />
      <StripeUpgradeSubscriptionModal
        selectedCompany={selectedCompany}
        open={modalUpgradeSubOpen}
        onClose={() => {
          setModalUpgradeSubOpen(false);
        }}
        onSave={(asaasSubcription, maxNumberUsers) => {
          setModalUpgradeSubOpen(false);
          onSave({ ...selectedCompany, maxNumberUsers, asaasSubscriptionId: asaasSubcription.id });
          onClose();
        }}
      />
      <StripeCreateScheduleSubscription
        selectedCompany={selectedCompany}
        open={modalScheduleSubscriptionOpen}
        onClose={() => {
          setModalScheduleSubscriptionOpen(false);
        }}
        onSave={(asaasSubcription, maxNumberUsers) => {
          setModalScheduleSubscriptionOpen(false);
          onSave({ ...selectedCompany, maxNumberUsers, asaasSubscriptionId: asaasSubcription.id });
          onClose();
        }}
      />
      <DialogTitle sx={{ paddingBottom: '0px' }}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography component="span" variant="h5">
                Stripe - {selectedCompany.name}
              </Typography>
            </Box>
          </Box>
          {stripeSubscriptionDetails?.length > 0 && (
            <Box display="flex" alignItems="center">
              <Chip color="info" label="Já possui assinatura" />
              <IconButton
                onClick={() => {
                  setModalUpgradeSubOpen(true);
                }}
                style={{ marginLeft: '10px' }}
              >
                <FontAwesomeDoubleIconStatus icon1="crown" icon2="angle-double-up" status="success" />
              </IconButton>
            </Box>
          )}
        </Box>
        <Tabs onChange={handleChange} value={value}>
          <Tab color="primary" label="Assinatura" />
          <Tab color="primary" label="Histórico de pagamentos" />
        </Tabs>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%' }}>
          {value === 0 && (
            <>
              <Typography
                component="span"
                variant="h5"
                sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
              >
                Assinaturas Agendadas
              </Typography>
              <Divider />
              {isLoadingSubscriptionScheduled ? (
                <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress color="inherit" size={20} />
                </Box>
              ) : (
                <>
                  {stripeSubscriptionScheduledDetails?.length > 0 ? (
                    <>
                      {stripeSubscriptionScheduledDetails?.map((subscriptionScheduled) => {
                        return (
                          <Grid
                            container
                            mt={2}
                            key={subscriptionScheduled.id}
                            justifyContent={'space-between'}
                          >
                            <Grid item>
                              <Box display="flex" flexDirection="row" alignItems={'center'}>
                                <Typography marginRight={'10px'}>
                                  {subscriptionScheduled?.plan?.name}{' '}
                                </Typography>
                                <Chip
                                  color="stripeGrey"
                                  label={`Começa em ${dayjs(subscriptionScheduled?.startDate).format(
                                    'DD [de] MMM[.] [de] YY',
                                  )} `}
                                  deleteIcon={
                                    <IconButton disabled>
                                      <FontAwesomeIcon color="stripeGrey" size="sm" icon="clock" />
                                    </IconButton>
                                  }
                                  onDelete={() => {}}
                                />
                                <IconButton
                                  onClick={() => {
                                    handleStripeDeleteScheduledSubscription();
                                  }}
                                >
                                  <FontAwesomeDoubleIconStatus
                                    icon1="trash"
                                    size1="md"
                                    icon2="clock"
                                    size2="xs"
                                    status="error"
                                  />
                                </IconButton>
                              </Box>

                              {subscriptionScheduled.status === 'canceled' ? (
                                <Typography variant="body2" color="textSecondary" component="span">
                                  Sem faturas futuras
                                </Typography>
                              ) : (
                                <Typography variant="body2" color="textSecondary" component="span">
                                  Faturamento {stripePeriodType[subscriptionScheduled?.plan?.periodType]}
                                  {dot}
                                  Próxima fatura: {NumberUtils.toCurrency(
                                    subscriptionScheduled?.plan?.price,
                                  )}{' '}
                                  em{' '}
                                  {dayjs(subscriptionScheduled?.startDate).format('DD [de] MMM[.] [de] YY')}{' '}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item sm={3} alignItems={'flex-end'}>
                              <Box>
                                {subscriptionScheduled?.paymentMethod ? (
                                  <>
                                    <Typography>
                                      <FontAwesomeIconStatus status="primary" icon="credit-card" />{' '}
                                      {subscriptionScheduled?.paymentMethod?.brand.toUpperCase()} {dotCard}
                                      {subscriptionScheduled?.paymentMethod?.last4}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      Vence em{' '}
                                      {
                                        monthUtils.getByIndex(
                                          subscriptionScheduled?.paymentMethod?.expMonth - 1,
                                        ).name
                                      }{' '}
                                      de {subscriptionScheduled?.paymentMethod?.expYear}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography variant="body2" color="textSecondary">
                                      Nenhum cartão cadastrado nessa assinatura
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    <Box sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}>
                      <HintText>Nenhuma assinatura agendada cadastrada.</HintText>
                    </Box>
                  )}
                </>
              )}
              <Divider />

              <Typography
                component="span"
                variant="h5"
                sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
              >
                Assinatura Atual
              </Typography>
              <Divider />
              {isLoadingSubscription ? (
                <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress color="inherit" size={20} />
                </Box>
              ) : (
                <>
                  {stripeSubscriptionDetails?.length > 0 ? (
                    <>
                      {stripeSubscriptionDetails?.map((stripeSubscription) => {
                        return (
                          <Grid
                            container
                            mt={2}
                            mb={2}
                            key={stripeSubscription.id}
                            justifyContent={'space-between'}
                          >
                            <Grid item>
                              <Box>
                                <Box display="flex" flexDirection="row" alignItems={'center'}>
                                  <Typography marginRight={'10px'}>
                                    {stripeSubscription?.plan?.name}
                                  </Typography>
                                  <Chip
                                    color={statusSubscription[stripeSubscription.status].color}
                                    label={statusSubscription[stripeSubscription.status].label}
                                  />
                                  {stripeSubscription.status !== 'canceled' && (
                                    <IconButton
                                      onClick={() => {
                                        handleStripeDeleteSubscription();
                                      }}
                                    >
                                      <FontAwesomeIconStatus icon="trash" status="error" />
                                    </IconButton>
                                  )}
                                  {stripeSubscription?.scheduled === true && (
                                    <>
                                      <Chip
                                        color="info"
                                        label={'Agendada - ' + stripeSubscription?.planScheduled}
                                      />
                                      <IconButton
                                        onClick={() => {
                                          handleStripeDeleteScheduledSubscription();
                                        }}
                                      >
                                        <FontAwesomeDoubleIconStatus
                                          icon1="trash"
                                          size1="md"
                                          icon2="clock"
                                          size2="xs"
                                          status="error"
                                        />
                                      </IconButton>
                                    </>
                                  )}
                                  {stripeSubscription?.cancelAt && (
                                    <>
                                      <Chip
                                        color="stripeGrey"
                                        label={`Cancela em ${dayjs(stripeSubscription?.cancelAt).format(
                                          'DD [de] MMM[.] [de] YY',
                                        )}`}
                                      />
                                      <IconButton
                                        onClick={() => {
                                          handleStripeReturnCanceledSubscription();
                                        }}
                                      >
                                        <FontAwesomeIconStatus icon="trash-restore" size="md" status="info" />
                                      </IconButton>
                                    </>
                                  )}
                                </Box>
                                {stripeSubscription.status === 'canceled' ? (
                                  <Typography variant="body2" color="textSecondary" component="span">
                                    Faturamento {stripePeriodType[stripeSubscription?.plan?.periodType]} {dot}{' '}
                                    Sem faturas futuras
                                  </Typography>
                                ) : (
                                  <>
                                    {stripeSubscription?.scheduled === true ? (
                                      <>
                                        {stripeSubscription?.nextInvoice ? (
                                          <Typography
                                            variant="body2"
                                            color={theme.palette.info.main}
                                            component="span"
                                          >
                                            Faturamento{' '}
                                            {stripePeriodType[stripeSubscription?.plan?.periodType]}
                                            {dot}
                                            Próxima fatura:{' '}
                                            {NumberUtils.toCurrency(
                                              stripeSubscription?.amountScheduled,
                                            )} em{' '}
                                            {dayjs(stripeSubscription?.nextInvoice).format(
                                              'DD [de] MMM[.] [de] YY',
                                            )}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="body2"
                                            color={theme.palette.info.main}
                                            component="span"
                                          >
                                            Faturamento{' '}
                                            {stripePeriodType[stripeSubscription?.plan?.periodType]}
                                            {dot}
                                            Próxima fatura:{' '}
                                            {NumberUtils.toCurrency(
                                              stripeSubscription?.amountScheduled,
                                            )} em{' '}
                                            {dayjs(stripeSubscription?.startDate).format(
                                              'DD [de] MMM[.] [de] YY',
                                            )}
                                          </Typography>
                                        )}
                                      </>
                                    ) : (
                                      <Typography variant="body2" color="textSecondary" component="span">
                                        Faturamento {stripePeriodType[stripeSubscription?.plan?.periodType]}
                                        {dot}
                                        Próxima fatura:{' '}
                                        {NumberUtils.toCurrency(
                                          stripeSubscription?.plan?.price -
                                            (stripeSubscription?.discount?.amountOff ?? 0),
                                        )}{' '}
                                        em{' '}
                                        {dayjs(stripeSubscription?.nextInvoice).format(
                                          'DD [de] MMM[.] [de] YY',
                                        )}
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </Box>
                            </Grid>
                            <Grid item sm={3} alignItems={'flex-end'}>
                              <Box>
                                {stripeSubscription?.status === 'canceled' ? (
                                  <>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                      Inicio em:{' '}
                                      {dayjs(stripeSubscription?.startDate).format('DD [de] MMM[.] [de] YY')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                      Encerrado em:{' '}
                                      {dayjs(stripeSubscription?.endedAt).format(
                                        'DD [de] MMM[.] [de] YY HH:mm',
                                      )}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    {stripeSubscription?.paymentMethod ? (
                                      <>
                                        <Typography>
                                          <FontAwesomeIconStatus status="primary" icon="credit-card" />{' '}
                                          {stripeSubscription?.paymentMethod?.brand.toUpperCase()} {dotCard}
                                          {stripeSubscription?.paymentMethod?.last4}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                          Vence em{' '}
                                          {
                                            monthUtils.getByIndex(
                                              stripeSubscription?.paymentMethod?.expMonth - 1,
                                            ).name
                                          }{' '}
                                          de {stripeSubscription?.paymentMethod?.expYear}
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        <Typography variant="body2" color="textSecondary">
                                          Nenhum cartão cadastrado nessa assinatura
                                        </Typography>
                                      </>
                                    )}
                                  </>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    <Box sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}>
                      <HintText>Nenhuma assinatura atual cadastrada.</HintText>
                    </Box>
                  )}
                </>
              )}
            </>
          )}

          {value === 1 && (
            <StripePaymentHistoryTable
              selectedCompany={selectedCompany}
              stripePaymentIntents={stripePaymentIntents}
              setStripePaymentIntents={setStripePaymentIntents}
              handleStripeCancelCharge={handleStripeCancelCharge}
              handleStripeRefundCharge={handleStripeRefundCharge}
              handleStripePayInvoice={handleStripePayInvoice}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActionsStyled>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleScheduleSubscription();
          }}
        >
          Agendar assinatura
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default StripeAccountInfoModal;
