import HttpService from './commons/HttpService';

const CompanyService = {
  search(nameOrEmail) {
    return HttpService.get('/companies/search', { params: { nameOrEmail } });
  },
  searchCustomer(name, companyId) {
    return HttpService.get('/companies/customerSearch', { params: { companyId, name } });
  },
  getById(id) {
    return HttpService.get(`/companies/${id}`);
  },
};

export default CompanyService;
