import HttpService from './commons/HttpService';

const SuperUserService = {
  getNewAccounts({ startDate, endDate }) {
    return HttpService.get('/superUserPrivateTools/newAccounts', { params: { startDate, endDate } });
  },

  getSubs() {
    return HttpService.get('/superUserPrivateTools/subscribers');
  },

  getAsaasOrStripeSubscribersByNumberOfUsers({ maxNumberOfUsers }) {
    return HttpService.get('/superUserPrivateTools/asaasOrStripeSubscribersByNumberOfUsers', { params: { maxNumberOfUsers } });
  },
};

export default SuperUserService;
