/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import showNotification from '../../commons/helpers/showNotification';
import SuperUserService from '../../services/SuperUserService';
import yup from '../../commons/validators/customYup';
import { FormDateField } from '../../components/forms/FormikFields';
import { useLocation } from 'react-router-dom';
import CompanyAppointmentPaymentsTable from './CompanyAppointmentPaymentsTable';

const schema = yup.object().shape({
  date: yup.date().nullable().required(),
});

const INIT_VALUES = {
  date: null,
};

// COMPONENT
function CompanyAppointmentPaymentsSearchPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [resultsInfo, setResultsInfo] = useState();

  const location = useLocation();
  const state = location.state;
  const company = state?.company;
  const companyId = company?.id;

  const myHandleSubmit = (values, actions) => {
    setIsLoading(true);
    SuperUserService.getAppointmentPaymentsReports({
      companyId: companyId,
      date: values.date.format('YYYY-MM-DD'),
    })
      .then((resp) => {
        if (resp.length === 0) {
          showNotification('warning', 'Nenhum resultado encontrado.');
        }
        setResultsInfo(resp);
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={INIT_VALUES}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ isSubmitting, setFieldValue, values, submitForm }) => (
          <Form>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Typography variant="subtitle1">Relatório de Agendamento com Pagamentos</Typography>
              {company && (
                <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
                  <Typography sx={{ mr: 2 }}>
                    <Typography variant="caption">Nome:</Typography> {company.name}
                  </Typography>
                  <Typography>
                    <Typography variant="caption">Email:</Typography> {company.email}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
              <Typography>Atalhos</Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button
                  onClick={() => {
                    setFieldValue('date', dayjs().subtract(1, 'day'));
                  }}
                >
                  Ontem ({dayjs().subtract(1, 'day').format('DD/MM/YYYY')})
                </Button>
                <Button
                  onClick={() => {
                    setFieldValue('date', dayjs());
                  }}
                >
                  Hoje ({dayjs().format('DD/MM/YYYY')})
                </Button>
                <Button
                  onClick={() => {
                    setFieldValue('date', dayjs().add(1, 'day'));
                  }}
                >
                  Amanhã ({dayjs().add(1, 'day').format('DD/MM/YYYY')})
                </Button>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 2 }}>
              <FormDateField name="date" label="Data" sx={{ ml: 2 }} />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={companyId == null || isSubmitting || isLoading}
                sx={{ ml: 2 }}
              >
                Buscar {(isSubmitting || isLoading) && <CircularProgress />}
              </Button>

              {values.date && (
                <Box sx={{ ml: 5 }}>
                  <Button
                    onClick={() => {
                      setFieldValue('date', values.date.subtract(1, 'day'));
                      submitForm();
                    }}
                    sx={{ mr: 4 }}
                  >
                    Prev
                  </Button>
                  <Button
                    onClick={() => {
                      setFieldValue('date', values.date.add(1, 'day'));
                      submitForm();
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
      {resultsInfo != null && resultsInfo.length > 0 && (
        <CompanyAppointmentPaymentsTable dataSource={resultsInfo} />
      )}
    </Box>
  );
}

export default CompanyAppointmentPaymentsSearchPage;
