import { Box, List, ListItem, ListItemText, Alert } from '@mui/material';
import React from 'react';
import NumberUtils from '../../../../commons/utils/numberUtils';

const TIME_SLOT_INTERVAL_OPTIONS = [
  { id: 5, name: 'De 5 em 5 minutos' },
  { id: 10, name: 'De 10 em 10 minutos' },
  { id: 15, name: 'De 15 em 15 minutos' },
  { id: 20, name: 'De 20 em 20 minutos' },
  { id: 25, name: 'De 25 em 25 minutos' },
  { id: 30, name: 'De 30 em 30 minutos' },
  { id: 40, name: 'De 40 em 40 minutos' },
  { id: 45, name: 'De 45 em 45 minutos' },
  { id: 50, name: 'De 50 em 50 minutos' },
  { id: 60, name: 'A cada 1 hora' },
  { id: 75, name: 'A cada 1 hora e 15 minutos' },
  { id: 90, name: 'A cada 1 hora e 30 minutos' },
  { id: 105, name: 'A cada 1 hora e 45 minutos' },
  { id: 120, name: 'A cada 2 horas' },
  { id: 135, name: 'A cada 2 horas e 15 minutos' },
  { id: 150, name: 'A cada 2 horas e 30 minutos' },
  { id: 165, name: 'A cada 2 horas e 45 minutos' },
  { id: 180, name: 'A cada 3 horas' },
  { id: 195, name: 'A cada 3 horas e 15 minutos' },
  { id: 210, name: 'A cada 3 horas e 30 minutos' },
  { id: 225, name: 'A cada 3 horas e 45 minutos' },
  { id: 240, name: 'A cada 4 horas' },
  { id: 255, name: 'A cada 4 horas e 15 minutos' },
  { id: 270, name: 'A cada 4 horas e 30 minutos' },
  { id: 285, name: 'A cada 4 horas e 45 minutos' },
  { id: 300, name: 'A cada 5 horas' },
];

const MIN_AMOUNT_OF_MINUTES_BEFORE_SCHEDULE_OPTIONS = [
  { id: 15, name: '15 minutos antes' },
  { id: 30, name: '30 minutos antes' },
  { id: 60, name: '1 hora antes' },
  { id: 120, name: '2 horas antes' },
  { id: 180, name: '3 horas antes' },
  { id: 240, name: '4 horas antes' },
  { id: 300, name: '5 horas antes' },
  { id: 360, name: '6 horas antes' },
  { id: 480, name: '8 horas antes' },
  { id: 720, name: '12 horas antes' },
  { id: 1440, name: '1 dia antes' },
  { id: 2880, name: '2 dias antes' },
  { id: 4320, name: '3 dias antes' },
  { id: 5760, name: '4 dias antes' },
  { id: 7200, name: '5 dias antes' },
  { id: 8640, name: '6 dias antes' },
  { id: 10080, name: '7 dias antes' },
];

const MAX_AMOUNT_OF_DAYS_AFTER_SCHEDULE_OPTIONS = [
  { id: 2, name: 'até 2 dias' },
  { id: 3, name: 'até 3 dias' },
  { id: 4, name: 'até 4 dias' },
  { id: 5, name: 'até 5 dias' },
  { id: 7, name: 'até 7 dias' },
  { id: 15, name: 'até 15 dias' },
  { id: 30, name: 'até 1 mês' },
  { id: 60, name: 'até 2 meses' },
  { id: 90, name: 'até 3 meses' },
  { id: 120, name: 'até 4 meses' },
  { id: 180, name: 'até 6 meses' },
  { id: 365, name: 'até 1 ano' },
];

// COMPONENT
function CompanyOnlineSchedulingSettingsInfo({ selectedCompany }) {
  const friendlyTimeSlotInterval = React.useMemo(() => {
    if (selectedCompany && selectedCompany.companyOnlineScheduling?.timeSlotInterval) {
      return TIME_SLOT_INTERVAL_OPTIONS.filter(
        (time) => time.id === selectedCompany.companyOnlineScheduling?.timeSlotInterval,
      )[0].name;
    }
    return '';
  }, [selectedCompany]);

  const friendlyMinAmountOfMinutesBeforeSchedule = React.useMemo(() => {
    if (selectedCompany && selectedCompany.companyOnlineScheduling?.minAmountOfMinutesBeforeSchedule) {
      return MIN_AMOUNT_OF_MINUTES_BEFORE_SCHEDULE_OPTIONS.filter(
        (minutes) => minutes.id === selectedCompany.companyOnlineScheduling?.minAmountOfMinutesBeforeSchedule,
      )[0].name;
    }
    return '-';
  }, [selectedCompany]);

  const friendlyMaxAmountOfDaysAfterSchedule = React.useMemo(() => {
    if (
      selectedCompany &&
      selectedCompany.companyOnlineScheduling &&
      selectedCompany.companyOnlineScheduling?.maxAmountOfDaysAfterSchedule
    ) {
      return MAX_AMOUNT_OF_DAYS_AFTER_SCHEDULE_OPTIONS.filter(
        (days) => days.id === selectedCompany.companyOnlineScheduling?.maxAmountOfDaysAfterSchedule,
      )[0].name;
    }
    return '-';
  }, [selectedCompany]);

  const cancellationAdvanceTimeMinutes = React.useMemo(() => {
    if (selectedCompany && selectedCompany.companyOnlineScheduling?.cancellationAdvanceTimeMinutes) {
      return MIN_AMOUNT_OF_MINUTES_BEFORE_SCHEDULE_OPTIONS.filter(
        (minutes) => minutes.id === selectedCompany.companyOnlineScheduling?.cancellationAdvanceTimeMinutes,
      )[0].name;
    }
    return '-';
  }, [selectedCompany]);

  const pixKeyConfigDescription = React.useMemo(() => {
    if (
      selectedCompany &&
      selectedCompany.companyOnlineScheduling?.pixKeyType &&
      selectedCompany.companyOnlineScheduling?.pixAnticipationFee
    ) {
      return `Chave PIX ${selectedCompany.companyOnlineScheduling?.pixKeyType} ${
        selectedCompany.companyOnlineScheduling?.pixKey
      } com taxa de ${NumberUtils.formatAsPercent(
        selectedCompany.companyOnlineScheduling.pixAnticipationFee,
        1,
      )}`;
    }
    return '-';
  }, [selectedCompany]);

  return (
    <div>
      <Alert severity={selectedCompany?.companyOnlineScheduling?.enabled ? 'success' : 'error'}>
        {selectedCompany?.companyOnlineScheduling?.enabled ? 'Link está ativo' : 'Link está desativado'}
      </Alert>

      <List dense>
        <Box sx={{ backgroundColor: '#d0d0d0', p: 1, fontSize: '12px' }}>Configuração geral</Box>
        <ListItem>
          <ListItemText primary="Intervalo de Horários" secondary={friendlyTimeSlotInterval} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Tempo de Antecedência Mínimo"
            secondary={friendlyMinAmountOfMinutesBeforeSchedule}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Período máximo para Agendar"
            secondary={friendlyMaxAmountOfDaysAfterSchedule}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Pertmir Cancelamento pelo cliente"
            secondary={
              selectedCompany?.companyOnlineScheduling?.allowSelfCancel
                ? `Sim - ${cancellationAdvanceTimeMinutes}`
                : 'Não'
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Texto Política de Cancelamento"
            secondary={
              selectedCompany?.companyOnlineScheduling?.cancelingPolicyText !== ''
                ? selectedCompany?.companyOnlineScheduling?.cancelingPolicyText
                : '-'
            }
          />
        </ListItem>

        <Box sx={{ backgroundColor: '#d0d0d0', p: 1, fontSize: '12px' }}>Textos a mostrar para o cliente</Box>
        <ListItem>
          <ListItemText
            primary="Mensagem de Instrução de Agendamento"
            secondary={
              selectedCompany?.companyOnlineScheduling?.appointmentWarningMessage !== ''
                ? selectedCompany?.companyOnlineScheduling?.appointmentWarningMessage
                : '-'
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="Chave PIX" secondary={pixKeyConfigDescription} />
        </ListItem>

        <Box sx={{ backgroundColor: '#d0d0d0', p: 1, fontSize: '12px' }}>Campos de cadastro</Box>

        <ListItem>
          <ListItemText
            primary="Mostrar campo Observação"
            secondary={selectedCompany?.companyOnlineScheduling?.commentsFieldShouldShow ? 'Sim' : 'Não'}
          />
        </ListItem>
        {selectedCompany?.companyOnlineScheduling?.commentsFieldShouldShow && (
          <ListItem>
            <ListItemText
              primary="Campo observação obrigatório"
              secondary={selectedCompany?.companyOnlineScheduling?.commentsFieldRequired ? 'Sim' : 'Não'}
            />
          </ListItem>
        )}
        <ListItem>
          <ListItemText
            primary="CPF obrigatório no cadastro"
            secondary={selectedCompany?.companyOnlineScheduling?.cpfRequired ? 'Sim' : 'Não'}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Campo data de nascimento habilitado"
            secondary={selectedCompany?.companyOnlineScheduling?.birthDateFieldEnabled ? 'Sim' : 'Não'}
          />
        </ListItem>

        <Box sx={{ backgroundColor: '#d0d0d0', p: 1, fontSize: '12px' }}>Outros</Box>
        <ListItem>
          <ListItemText
            primary="Permitir agendamento sem login"
            secondaryTypographyProps={{ component: 'div' }}
            secondary={selectedCompany?.companyOnlineScheduling?.allowAppointmentAsGuest ? 'Sim' : 'Não'}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Permitir agendamento com telefone"
            secondaryTypographyProps={{ component: 'div' }}
            secondary={selectedCompany?.companyOnlineScheduling?.allowLoginWithPhone ? 'Sim' : 'Não'}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Permitir agendamento em feriados"
            secondary={selectedCompany?.companyOnlineScheduling?.canScheduleOnHolidays ? 'Sim' : 'Não'}
          />
        </ListItem>

        <Box sx={{ backgroundColor: '#d0d0d0', p: 1, fontSize: '12px' }}>
          Configuração não exposta para clientes
        </Box>
        <ListItem>
          <ListItemText
            primary="Número máximo de agendamentos futuros"
            secondary={selectedCompany?.companyOnlineScheduling?.maxNumberOfFutureAppointments ?? '-'}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Dar Baixa De Atendimento Automaticamente"
            secondary={selectedCompany?.companyOnlineScheduling?.automaticAppointmentPayment ? 'Sim' : 'Não'}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Permitir agendamento Simultaneo"
            secondary={selectedCompany?.companyOnlineScheduling?.allowSimultaneousAppointment ? 'Sim' : 'Não'}
          />
        </ListItem>
      </List>
    </div>
  );
}

export default CompanyOnlineSchedulingSettingsInfo;
