import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import SuperUserService from '../../../services/SuperUserService';
import dayjs from 'dayjs';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function CompanySubscriptionInfoModal({ selectedCompany, modalVisible, setModalVisible }) {
  const [dataSource, setDataSource] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modalVisible) {
      setIsLoading(true);
      SuperUserService.getCompanySubscriptionInfo(selectedCompany.id)
        .then((result) => {
          setDataSource(result != null ? [result] : []);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [modalVisible, selectedCompany]);

  // const handleSyncInfo = () => {
  //   setIsLoading(true);
  //   SuperUserService.syncCompanySubscriptionInfo({ companyId: selectedCompany.id })
  //     .then(() => {
  //       return SuperUserService.getCompanySubscriptionInfo(selectedCompany.id).then((result) => {
  //         setDataSource(result != null ? [result] : []);
  //       });
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // const handleUpdateSubInfo = () => {
  //   const answer = window.confirm(
  //     `Você tem certeza que deseja tornar Não Elegivel para desconto introdutorio?\nCUIDADO:\nNAO É POSSIVEL REVERTER PELO PAINEL!`,
  //   );

  //   if (answer) {
  //     setIsLoading(true);
  //     SuperUserService.updateCompanySubscriptionInfo({
  //       companyId: selectedCompany.id,
  //       notEligibleForIntroDiscount: true,
  //     })
  //       .then(() => {
  //         return SuperUserService.getCompanySubscriptionInfo(selectedCompany.id).then((result) => {
  //           setDataSource(result != null ? [result] : []);
  //         });
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible} maxWidth="xl">
      <DialogTitle variant="h5">Dados da assinatura - {selectedCompany.name}</DialogTitle>

      <Box sx={{ mt: 1 }}>
        {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : <Box sx={{ height: '4px' }} />}
      </Box>
      <DialogContent dividers>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Periodicidade</TableCell>
              <TableCell>Subscription Customer Id</TableCell>
              <TableCell>Subscription Id</TableCell>
              <TableCell>Criado em</TableCell>
              <TableCell>Atualizado em</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource?.map((c) => {
              return (
                <TableRow key={c.id}>
                  <TableCell>{c.subscriptionType}</TableCell>
                  <TableCell>{c.subscriptionPeriodType}</TableCell>
                  <TableCell>{c.subscriptionCustomerId}</TableCell>
                  <TableCell>{c.subscriptionId}</TableCell>
                  <TableCell>{dayjs(c.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                  <TableCell>{c.updatedAt ? dayjs(c.updatedAt).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActionsStyled>
        <Button variant="outlined" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default CompanySubscriptionInfoModal;
