import {
  Box,
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import NumberUtils from '../../../commons/utils/numberUtils';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import HintText from '../../../components/labels/HintText';
import TableCellAction from '../../../components/table/TableCellAction';
import SuperUserService from '../../../services/SuperUserService';
import StripeDetailPaymentIntentModal from './StripeDetailInvoiceModal';
import StripeDisputeDocumentsModal from './StripeDisputeDocumentsModal';
import { failures, statusCharge, statusInvoice, statusOutcome } from './stripeHelpers';

const getDisputedLabel = (status) => {
  if (status === 'lost') {
    return 'Contestação perdida';
  }
  if (status === 'won') {
    return 'Contestação vencida';
  }
  if (status === 'under_review') {
    return 'Contestação em analise';
  }
  return 'Em contestação';
};

function StripePaymentHistoryTable({
  selectedCompany,
  stripePaymentIntents,
  setStripePaymentIntents,
  isLoading,
  setIsLoading,
  handleStripeCancelCharge,
  handleStripeRefundCharge,
  handleStripePayInvoice,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalDetailPaymentIntentOpen, setModalDetailPaymentIntentOpen] = useState(false);
  const [modalStripeDisputeDocumentsOpen, setModalStripeDisputeDocumentsOpen] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [selectedChargeId, setSelectedChargeId] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handlePopoverOpen = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const handleDetailInvoice = (invoice) => {
    if (invoice.paymentIntent) {
      setIsLoading(true);
      SuperUserService.stripeChargesByPaymentIntent({
        companyId: selectedCompany.id,
        paymentIntentId: invoice.paymentIntent,
      })
        .then((details) => {
          setPaymentIntent(details);
          setModalDetailPaymentIntentOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setPaymentIntent(null);
      setModalDetailPaymentIntentOpen(true);
    }
  };

  const handleDownloadModal = (chargeId) => {
    setSelectedChargeId(chargeId);
    setModalStripeDisputeDocumentsOpen(true);
  };
  useEffect(() => {
    if (stripePaymentIntents === null) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const stripePaymentIntents = await SuperUserService.getAllStripePaymentIntents(selectedCompany.id);
          setStripePaymentIntents(stripePaymentIntents);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [setIsLoading, selectedCompany.id, stripePaymentIntents, setStripePaymentIntents]);

  return (
    <>
      <StripeDetailPaymentIntentModal
        detailPaymentIntent={paymentIntent}
        open={modalDetailPaymentIntentOpen}
        onClose={() => {
          setModalDetailPaymentIntentOpen(false);
        }}
      />
      <StripeDisputeDocumentsModal
        selectedCompany={selectedCompany}
        chargeId={selectedChargeId}
        open={modalStripeDisputeDocumentsOpen}
        onClose={() => {
          setModalStripeDisputeDocumentsOpen(false);
        }}
      />
      {isLoading && (
        <Box>
          <LinearProgress size={24} />
        </Box>
      )}
      {stripePaymentIntents?.length > 0 ? (
        <>
          <Typography
            component="span"
            variant="h5"
            sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
          >
            Faturas
          </Typography>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Valor</TableCell>
                <TableCell>Número da Fatura</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell>Data Pagamento</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stripePaymentIntents?.map((paymentIntent, i) => {
                if (paymentIntent.invoice) {
                  const canRefund =
                    paymentIntent.invoice.status === 'paid' &&
                    paymentIntent.invoice.created &&
                    dayjs().diff(dayjs(paymentIntent.invoice.created), 'day') <= 7;
                  return (
                    <TableRow key={paymentIntent.invoice.id}>
                      <TableCell>{NumberUtils.toCurrency(paymentIntent.invoice.amount, 2)}</TableCell>
                      <TableCell>
                        {paymentIntent.invoice.number}
                        {'  '}
                        <>
                          <Chip
                            color={statusInvoice[paymentIntent.invoice.status].color}
                            label={statusInvoice[paymentIntent.invoice.status].label}
                          />
                        </>
                      </TableCell>
                      {paymentIntent.invoice.created ? (
                        <TableCell>
                          {dayjs(paymentIntent.invoice.created).format('DD [de] MMM[.] [de] YY')}
                        </TableCell>
                      ) : (
                        <TableCell> --- </TableCell>
                      )}
                      {paymentIntent.invoice.paidAt ? (
                        <TableCell>
                          {dayjs(paymentIntent.invoice.paidAt).format('DD [de] MMM[.] [de] YY HH:mm')}
                        </TableCell>
                      ) : (
                        <TableCell> --- </TableCell>
                      )}
                      <TableCellAction
                        title="Detalhes"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleDetailInvoice(paymentIntent.invoice);
                        }}
                      >
                        {paymentIntent.invoice.statusCharge !== null ? (
                          <Chip
                            color={statusCharge[paymentIntent.invoice.statusCharge].color}
                            label={statusCharge[paymentIntent.invoice.statusCharge].label}
                            onMouseEnter={(e) => handlePopoverOpen(e, paymentIntent.invoice.id)}
                            onMouseLeave={handlePopoverClose}
                          />
                        ) : (
                          <>
                            {paymentIntent.invoice.status === 'paid' ? (
                              <Chip
                                color="success"
                                label="Ok"
                                onMouseEnter={(e) => handlePopoverOpen(e, paymentIntent.invoice.id)}
                                onMouseLeave={handlePopoverClose}
                              />
                            ) : (
                              <Chip
                                color={statusCharge[paymentIntent.invoice.status].color}
                                label={statusCharge[paymentIntent.invoice.status].label}
                                onMouseEnter={(e) => handlePopoverOpen(e, paymentIntent.invoice.id)}
                                onMouseLeave={handlePopoverClose}
                              />
                            )}
                          </>
                        )}

                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: 'none',
                          }}
                          open={openedPopoverId === paymentIntent.invoice.id}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          {(paymentIntent.invoice.outcomeReason !== null ||
                            paymentIntent.invoice.failureCode !== null) &&
                          paymentIntent.invoice.status !== 'paid' ? (
                            <>
                              {paymentIntent.invoice.outcomeReason !== null ? (
                                <Typography sx={{ p: 1 }}>
                                  {statusOutcome[paymentIntent.invoice.outcomeReason]}
                                </Typography>
                              ) : (
                                <>
                                  {paymentIntent.invoice.failureCode !== null && (
                                    <Typography sx={{ p: 1 }}>
                                      {failures[paymentIntent.invoice.failureCode]}
                                    </Typography>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {paymentIntent.invoice.status === 'paid' ? (
                                <Typography sx={{ p: 1 }}>Pagamento concluído</Typography>
                              ) : (
                                <Typography sx={{ p: 1 }}>Clique para mais detalhes</Typography>
                              )}
                            </>
                          )}
                        </Popover>
                      </TableCellAction>

                      {paymentIntent.invoice.refunds?.length > 0 &&
                      !paymentIntent.invoice.refunded &&
                      canRefund ? (
                        <>
                          <TableCellAction
                            title="Reembolsar"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleStripeRefundCharge(paymentIntent.invoice);
                            }}
                          >
                            <Chip color="warning" label="Reembolso parcial" />
                          </TableCellAction>
                          <TableCell />
                        </>
                      ) : (
                        <>
                          {paymentIntent.invoice.refunded ? (
                            <>
                              <TableCell>
                                <Chip color="warning" label="Reembolsado" />
                              </TableCell>
                              <TableCell />
                            </>
                          ) : (
                            <>
                              {paymentIntent.invoice.status === 'paid' &&
                              paymentIntent.invoice.chargeId != null &&
                              canRefund ? (
                                <>
                                  <TableCellAction
                                    title="Reembolsar"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      handleStripeRefundCharge(paymentIntent.invoice);
                                    }}
                                  >
                                    <FontAwesomeIconStatus status="warning" icon="hand-holding-dollar" />
                                  </TableCellAction>
                                  <TableCell />
                                </>
                              ) : (
                                <>
                                  {paymentIntent.invoice.status === 'open' ||
                                  paymentIntent.invoice.status === 'draft' ? (
                                    <>
                                      <TableCellAction
                                        title="Tentar cobrar novamente"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          handleStripePayInvoice(paymentIntent.invoice);
                                        }}
                                        actionsDisabled={isLoading}
                                      >
                                        <FontAwesomeIconStatus status="primary" icon="repeat" />
                                      </TableCellAction>
                                      <TableCellAction
                                        title="Anular cobrança"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          handleStripeCancelCharge(paymentIntent.invoice);
                                        }}
                                      >
                                        <FontAwesomeIconStatus status="warning" icon="ban" />
                                      </TableCellAction>
                                    </>
                                  ) : (
                                    <>
                                      <TableCell />
                                      <TableCell />
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TableRow>
                  );
                } else {
                  return '';
                }
              })}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box sx={{ mt: 5, mb: 5, display: 'flex', justifyContent: 'center' }}>
          <HintText>Nenhum registro de fatura localizado.</HintText>
        </Box>
      )}
      {stripePaymentIntents?.length > 0 ? (
        <>
          <Typography
            component="span"
            variant="h5"
            sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
          >
            Pagamentos
          </Typography>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Valor</TableCell>
                <TableCell></TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stripePaymentIntents?.map((paymentIntent, i) => {
                if (paymentIntent.charge) {
                  return (
                    <TableRow key={paymentIntent.charge.id}>
                      <TableCell>{NumberUtils.toCurrency(paymentIntent.charge.amount, 2)}</TableCell>
                      <TableCell>
                        {paymentIntent.charge?.disputed ? (
                          <>
                            <Chip
                              color={'info'}
                              label={getDisputedLabel(paymentIntent.charge.status)}
                              onMouseEnter={(e) => handlePopoverOpen(e, paymentIntent.charge.id)}
                              onMouseLeave={handlePopoverClose}
                            />
                            <Popover
                              id="mouse-over-popover"
                              sx={{
                                pointerEvents: 'none',
                              }}
                              open={openedPopoverId === paymentIntent.charge.id}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              <Typography sx={{ p: 1 }}>Verifique detalhes no painel Stripe</Typography>
                            </Popover>

                            <IconButton
                              onClick={() => {
                                handleDownloadModal(paymentIntent.charge.id);
                              }}
                            >
                              <FontAwesomeIconStatus icon="download" status="info" />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <Chip
                              color={statusInvoice[paymentIntent.charge.status].color}
                              label={statusInvoice[paymentIntent.charge.status].label}
                              onMouseEnter={(e) => handlePopoverOpen(e, paymentIntent.charge.id)}
                              onMouseLeave={handlePopoverClose}
                            />
                            <Popover
                              id="mouse-over-popover"
                              sx={{
                                pointerEvents: 'none',
                              }}
                              open={openedPopoverId === paymentIntent.charge.id}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              {(paymentIntent.charge.outcomeReason !== null ||
                                paymentIntent.charge.failureCode !== null) &&
                              paymentIntent.charge.status !== 'succeeded' ? (
                                <>
                                  {paymentIntent.charge.outcomeReason !== null ? (
                                    <Typography sx={{ p: 1 }}>
                                      {statusOutcome[paymentIntent.charge.outcomeReason]}
                                    </Typography>
                                  ) : (
                                    <>
                                      {paymentIntent.charge.failureCode !== null && (
                                        <Typography sx={{ p: 1 }}>
                                          {failures[paymentIntent.charge.failureCode]}
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <Typography sx={{ p: 1 }}>Pagamento concluído</Typography>
                              )}
                            </Popover>
                          </>
                        )}
                      </TableCell>
                      <TableCell>{paymentIntent.charge.description}</TableCell>
                      {paymentIntent.charge.created ? (
                        <TableCell>
                          {dayjs(paymentIntent.charge.created).format('DD [de] MMM[.] [de] YY HH:mm')}
                        </TableCell>
                      ) : (
                        <TableCell> --- </TableCell>
                      )}
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={paymentIntent.id}>
                      <TableCell>{NumberUtils.toCurrency(paymentIntent.amount, 2)}</TableCell>
                      <TableCell>
                        {paymentIntent.status === 'requires_action' && (
                          <>
                            <Chip
                              color={'info'}
                              label="Requer ação do usuário"
                              onMouseEnter={(e) => handlePopoverOpen(e, paymentIntent.id)}
                              onMouseLeave={handlePopoverClose}
                            />
                            <Popover
                              id="mouse-over-popover"
                              sx={{
                                pointerEvents: 'none',
                              }}
                              open={openedPopoverId === paymentIntent.id}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              <Typography sx={{ p: 1 }}>Verifique detalhes no painel Stripe</Typography>
                            </Popover>
                          </>
                        )}
                      </TableCell>
                      <TableCell>{paymentIntent.description}</TableCell>
                      {paymentIntent.created ? (
                        <TableCell>
                          {dayjs(paymentIntent.created).format('DD [de] MMM[.] [de] YY HH:mm')}
                        </TableCell>
                      ) : (
                        <TableCell> --- </TableCell>
                      )}
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box sx={{ mt: 5, mb: 5, display: 'flex', justifyContent: 'center' }}>
          <HintText>Nenhum registro de fatura localizado.</HintText>
        </Box>
      )}
    </>
  );
}
export default StripePaymentHistoryTable;
