import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import showNotification from '../../commons/helpers/showNotification';
import SuperUserPrivateService from '../../services/SuperUserPrivateService';
import dayjs from 'dayjs';
import exportToCsv from '../../commons/helpers/exportToCsv';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';

// COMPONENT
function AdmDashboardSubsByNumberOfUsersPage() {
  const [maxNumberOfUsers, setMaxNumberOfUsers] = useState(25);

  // filters
  const [subscriptionType, setSubscriptionType] = useState('ALL');
  const [planPeriodType, setPlanPeriodType] = useState('ALL');

  // sort
  const [sortedByProp, setSortedByProp] = useState();
  const [sortedByAscending, setSortedByAscending] = useState(true);

  const [dataSource, setDataSource] = useState([
    {
      company: {
        name: 'Rodrigo',
        subscriptionType: 'STRIPE',
      },
      planPeriodType: 'MONTHLY',
    },
    {
      company: {
        name: 'Roger',
        subscriptionType: 'STRIPE',
      },
      planPeriodType: 'MONTHLY',
    },
    {
      company: {
        name: 'Amanda',
        subscriptionType: 'ASAAS',
      },
      planPeriodType: 'YEARLY',
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const myHandleSubmit = async () => {
    try {
      setIsLoading(true);
      const resp = await SuperUserPrivateService.getAsaasOrStripeSubscribersByNumberOfUsers({
        maxNumberOfUsers,
      });

      if (resp.length === 0) {
        showNotification('warning', 'Nenhum resultado encontrado.');
      }

      setDataSource(resp);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = useMemo(() => {
    if (dataSource && dataSource.length > 0) {
      const filtered = dataSource.filter((obj) => {
        return (
          (obj.company.subscriptionType === subscriptionType || subscriptionType === 'ALL') &&
          (obj.planPeriodType === planPeriodType || planPeriodType === 'ALL')
        );
      });

      if (sortedByProp == null) {
        return filtered;
      }
      return filtered.sort((objA, objB) => {
        let valueA = '';
        let valueB = '';
        if (sortedByProp === 'planPeriodType') {
          valueA = objA[sortedByProp];
          valueB = objB[sortedByProp];
        } else {
          valueA = objA['company'][sortedByProp];
          valueB = objB['company'][sortedByProp];
        }

        if (valueA == null) {
          valueA = '';
        }
        if (valueB == null) {
          valueB = '';
        }

        return sortedByAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      });
    }
    return 0;
  }, [dataSource, subscriptionType, planPeriodType, sortedByProp, sortedByAscending]);

  const handleExport = async () => {
    try {
      const preparedDataSource = filteredData.map((object) => {
        const baseObj = {
          NAME: object.company.name,
          EMAIL: object.company.email,
        };
        return baseObj;
      });

      const fileName = `RelatorioSubs_Plano${maxNumberOfUsers}_PlanPeriod${planPeriodType}_Gerado${dayjs().format(
        'DD-MM-YY HH:mm:ss',
      )}`;
      exportToCsv(preparedDataSource, fileName);
      showNotification('success', 'Relatório gerado com sucesso.');
    } finally {
    }
  };

  return (
    <>
      <Box sx={{ s: 3 }}>
        <Box display="flex" flexDirection="row">
          <FormControl>
            <InputLabel>Número de usuários</InputLabel>
            <Select
              label={`Número de usuários`}
              value={maxNumberOfUsers}
              onChange={(event) => {
                setMaxNumberOfUsers(event.target.value);
              }}
              disabled={isLoading}
              sx={{ minWidth: '200px' }}
            >
              <MenuItem value={1}>Individual</MenuItem>
              <MenuItem value={3}>Empresarial - 3 usuários</MenuItem>
              <MenuItem value={6}>Empresarial - 6 usuários</MenuItem>
              <MenuItem value={9}>Empresarial - 9 usuários</MenuItem>
              <MenuItem value={12}>Empresarial - 12 usuários</MenuItem>
              <MenuItem value={18}>Empresarial - 18 usuários</MenuItem>
              <MenuItem value={25}>Empresarial - 25 usuários</MenuItem>
              <MenuItem value={35}>Empresarial - 35 usuários</MenuItem>
              <MenuItem value={50}>Empresarial - 50 usuários</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{ ml: 2 }}
            onClick={myHandleSubmit}
          >
            Buscar {isLoading && <CircularProgress />}
          </Button>
        </Box>

        <Box sx={{ mt: 1 }}>
          {isLoading ? <LinearProgress style={{ width: '100%' }} /> : <div style={{ height: '4px' }} />}
        </Box>

        {dataSource?.length > 0 && (
          <Box sx={{ mt: 1, maxWidth: '400px' }}>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 2 }}>
              <Typography sx={{ mr: 1 }}>Filtros</Typography>
              <Box>
                <FormControl>
                  <InputLabel>Tipo de Assinatura</InputLabel>
                  <Select
                    label="Tipo de assinatura"
                    value={subscriptionType}
                    onChange={(event) => {
                      setSubscriptionType(event.target.value);
                    }}
                    disabled={isLoading}
                    sx={{ minWidth: '200px' }}
                  >
                    <MenuItem value="ALL">Todos</MenuItem>
                    <MenuItem value="ASAAS">ASAAS</MenuItem>
                    <MenuItem value="STRIPE">STRIPE</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ ml: 2 }}>
                <FormControl>
                  <InputLabel>Periodicidade</InputLabel>
                  <Select
                    label="Periodicidade"
                    value={planPeriodType}
                    onChange={(event) => {
                      setPlanPeriodType(event.target.value);
                    }}
                    disabled={isLoading}
                    sx={{ minWidth: '200px' }}
                  >
                    <MenuItem value="ALL">Todos</MenuItem>
                    <MenuItem value="MONTHLY">MONTHLY</MenuItem>
                    <MenuItem value="YEARLY">YEARLY</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Button
                variant="outlined"
                color="primary"
                onClick={handleExport}
                sx={{ ml: 2, px: 8 }}
                fullWidth
              >
                {' '}
                Exportar{' '}
              </Button>
            </Box>

            <Divider />
            <Box display="flex" flexDirection="row" sx={{ mt: 1 }}>
              <Typography color="default" variant="body1">
                Num. de registros total: {dataSource.length}
              </Typography>
              <Typography color="default" variant="body1" sx={{ ml: 1 }}>
                Num. de registros filtrados: {filteredData.length}
              </Typography>
            </Box>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box display="flex" flexDirection="row">
                      <Typography
                        onClick={() => {
                          if (sortedByProp == null || sortedByProp !== 'name') {
                            setSortedByAscending(true);
                          } else if (sortedByAscending) {
                            // go to descending
                            setSortedByAscending(false);
                          } else {
                            // disabled
                            setSortedByAscending(true);
                            setSortedByProp(null);
                          }
                          setSortedByProp('name');
                        }}
                        variant="subtitle2"
                        sx={{ cursor: 'pointer' }}
                      >
                        Nome
                      </Typography>{' '}
                      {sortedByProp === 'name' && (
                        <FontAwesomeIconStatus
                          status="primary"
                          icon={sortedByAscending ? 'caret-down' : 'caret-up'}
                          style={{ marginLeft: '5px' }}
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="row">
                      <Typography
                        onClick={() => {
                          if (sortedByProp == null || sortedByProp !== 'subscriptionType') {
                            setSortedByAscending(true);
                          } else if (sortedByAscending) {
                            // go to descending
                            setSortedByAscending(false);
                          } else {
                            // disabled
                            setSortedByAscending(true);
                            setSortedByProp(null);
                          }
                          setSortedByProp('subscriptionType');
                        }}
                        variant="subtitle2"
                        sx={{ cursor: 'pointer' }}
                      >
                        Tipo de Assinatura
                      </Typography>{' '}
                      {sortedByProp === 'subscriptionType' && (
                        <FontAwesomeIconStatus
                          status="primary"
                          icon={sortedByAscending ? 'caret-down' : 'caret-up'}
                          style={{ marginLeft: '5px' }}
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="row">
                      <Typography
                        onClick={() => {
                          if (sortedByProp == null || sortedByProp !== 'planPeriodType') {
                            setSortedByAscending(true);
                          } else if (sortedByAscending) {
                            // go to descending
                            setSortedByAscending(false);
                          } else {
                            // disabled
                            setSortedByAscending(true);
                            setSortedByProp(null);
                          }
                          setSortedByProp('planPeriodType');
                        }}
                        variant="subtitle2"
                        sx={{ cursor: 'pointer' }}
                      >
                        Periodicidade
                      </Typography>{' '}
                      {sortedByProp === 'planPeriodType' && (
                        <FontAwesomeIconStatus
                          status="primary"
                          icon={sortedByAscending ? 'caret-down' : 'caret-up'}
                          style={{ marginLeft: '5px' }}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.map((c) => {
                  return (
                    <TableRow key={c.company.id}>
                      <TableCell>{c.company.name}</TableCell>
                      <TableCell>{c.company.email}</TableCell>
                      <TableCell>{c.company.subscriptionType}</TableCell>
                      <TableCell>{c.planPeriodType}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </>
  );
}

export default AdmDashboardSubsByNumberOfUsersPage;
