import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Tooltip } from '@mui/material';
import FontAwesomeDoubleIconStatus from '../../components/icon/FontAwesomeDoubleIconStatus';

const ActionButton = {
  USUARIOS: 2,
  HISTORICO_LOGIN: 3,
  LOGS_REC_SENHA: 4,
  CREATE_TEMP_SENHA: 5,
  LINK_ATIVACAO: 6,
  LINK_UPGRADE: 7,
  ACCOUNT_DELETE: 8,
  ACCOUNT_RESET: 9,
  LINK_ATIVACAO_ASAAS: 21,
};

function TopMainButtons({ onClickButton, endSlot }) {
  const buttons = [
    {
      type: ActionButton.USUARIOS,
      title: 'Usuários',
      iconName: 'user-group',
      iconColor: 'secondary',
      toolTipMessage: 'Lista de usuários',
      disabled: false,
    },
    // {
    //   type: ActionButton.HISTORICO_LOGIN,
    //   title: 'Histórico de Login',
    //   iconName: 'history',
    //   iconColor: 'secondary',
    //   disabled: false,
    // },
    // {
    //   type: ActionButton.CREATE_TEMP_SENHA,
    //   title: 'Senha Temporária',
    //   modalConfirmMessage: 'Você tem certeza que deseja criar uma nova senha temporária?',
    //   iconName: 'lock',
    //   iconColor: 'secondary',
    //   disabled: false,
    // },
    {
      type: ActionButton.LINK_ATIVACAO,
      title: 'Ative Stripe',
      modalConfirmMessage:
        'Você tem certeza que deseja gerar o link de ativação? Ele dura 21 dias e se vc ja tiver gerado outro para este usuario, o link antigo ira se invalidar.',
      iconName: 'crown',
      iconColor: 'warning',
      toolTipMessage: 'Gerar link de ativação',
      disabled: false,
      dateFieldLabel: 'Data de Inicio da Assinatura (PARA PAGAMENTO FUTURO)',
      checkBoxFieldLabel: 'Não elegivel para desconto introdutorio',
      alert: 'Você pode informar a data para agendar para o futuro',
      messageStyle: { marginBottom: '10px' },
    },
    {
      type: ActionButton.LINK_ATIVACAO_ASAAS,
      title: 'Ative Asaas',
      modalConfirmMessage:
        'Você tem certeza que deseja gerar o link de ativação ASAAS? Ele dura 21 dias e se vc ja tiver gerado outro para este usuario, o link antigo ira se invalidar.',
      iconName: 'crown',
      iconColor: 'info',
      toolTipMessage: 'Gerar link de ativação',
      disabled: false,
    },
    {
      type: ActionButton.LINK_UPGRADE,
      title: 'Link Upgrade',
      modalConfirmMessage:
        'Você tem certeza que deseja gerar o link de UPGRADE? Ele dura 48hs e se vc ja tiver gerado outro para este usuario, o link antigo ira se invalidar.',
      iconName: 'crown',
      iconColor: 'success',
      toolTipMessage: 'Gerar link de upgrade',
      disabled: false,
    },
    {
      type: ActionButton.ACCOUNT_DELETE,
      title: 'Deletar',
      modalConfirmMessage: 'Você tem certeza que deseja deletar a conta?',
      textConfirm: 'CONFIRMAR DELETAR',
      iconName: 'trash',
      iconColor: 'explicitError',
      disabled: false,
    },
    {
      type: ActionButton.ACCOUNT_RESET,
      title: 'Resetar',
      modalConfirmMessage: 'Você tem certeza que deseja resetar a conta?',
      textConfirm: 'CONFIRMAR RESETAR',
      iconName: 'rotate-left',
      iconColor: 'explicitError',
      disabled: false,
    },
  ];

  return (
    <Grid container rowGap={1}>
      {buttons.map((button, index) => (
        <Tooltip
          key={index}
          title={button.toolTipMessage ? button.toolTipMessage : ''}
          disableHoverListener={button.toolTipMessage ? false : true}
        >
          <Button
            variant="outlined"
            sx={{ mr: 1, mb: 1 }}
            onClick={() => onClickButton(button)}
            disabled={button.disabled}
            startIcon={
              button.secondIconName ? (
                <FontAwesomeDoubleIconStatus icon1={button.iconName} status={button.iconColor} />
              ) : (
                <FontAwesomeIcon icon={button.iconName} color={button.iconColor} />
              )
            }
            color={button.iconColor}
          >
            {button.title}
          </Button>
        </Tooltip>
      ))}

      {endSlot}
    </Grid>
  );
}

export { TopMainButtons, ActionButton };
