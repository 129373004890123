import React, { useState, useRef } from 'react';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useTable, usePagination, useSortBy, useFilters } from 'react-table';

import TableCellAction from './TableCellAction';
import ConfirmationDialog from '../modals/ConfirmationDialog';
import DefaultColumnFilter from './DefaultColumnFilter';
import { filterTypes } from './filterTypes';

// COMPONENT
function SimpleReactTable({
  onSelectCallback,
  dataSource,
  columns,
  initialState,
  onDeleteCallback,
  onEditCallback,
  hideEdit,
  hideDelete,
  customActions,
  actionsDisabled,
  id,
  size,
}) {
  const classes = {};
  const refObjectToDelete = useRef();
  const [isModalDeleteOpened, setModalDeleteOpened] = useState(false);

  // REACT TABLE
  const memoFilterTypes = React.useMemo(() => filterTypes, []);

  const DEFAULT_COLUMN = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    filteredRows,
  } = useTable(
    {
      columns,
      data: dataSource,
      initialState,
      defaultColumn: DEFAULT_COLUMN,
      filterTypes: memoFilterTypes,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const handleDeleteConfirmation = () => {
    onDeleteCallback(refObjectToDelete.current);
    setModalDeleteOpened(false);
    refObjectToDelete.current = null;
  };

  const handleDelete = (objectToDelete) => {
    refObjectToDelete.current = objectToDelete.original;
    setModalDeleteOpened(true);
  };

  const handleEdit = (object) => {
    onEditCallback(object.original);
  };

  return (
    <React.Fragment>
      <ConfirmationDialog
        title="Exclusão"
        message="Você tem certeza que deseja deletar?"
        open={isModalDeleteOpened}
        onConfirm={handleDeleteConfirmation}
        onClose={() => {
          refObjectToDelete.current = null;
          setModalDeleteOpened(false);
        }}
      />

      <Card className={classes.root}>
        <CardContent className={classes.content}>
          {/* TABLE */}
          <div>
            <Table {...getTableProps()} id={id} size={size}>
              <TableHead>
                <TableRow>
                  {headers.map((column) => {
                    return (
                      <TableCell {...column.getHeaderProps()}>
                        <TableSortLabel
                          active={column.isSorted && column.isSortedDesc != null}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          {...column.getSortByToggleProps()}
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                        <div>{column.canFilter && !column.noFilter ? column.render('Filter') : null}</div>
                      </TableCell>
                    );
                  })}
                  {customActions && customActions.map((a) => <TableCell key={a.title} />)}
                  {!hideEdit && <TableCell />}
                  {!hideDelete && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover={onSelectCallback != null}
                      {...row.getRowProps()}
                      onClick={() => {
                        if (onSelectCallback) {
                          onSelectCallback(row);
                        }
                      }}
                    >
                      {row.cells.map((cell) => {
                        return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                      })}
                      {customActions &&
                        customActions.map((action) => (
                          <TableCellAction
                            key={action.title}
                            title={action.title}
                            onClick={(e) => {
                              action.handler(row, e);
                            }}
                            buttonClassName={classes.tableActionMargin}
                            disabled={actionsDisabled}
                          >
                            {action.icon}
                          </TableCellAction>
                        ))}
                      {!hideEdit && (
                        <TableCellAction
                          title="Editar"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleEdit(row);
                          }}
                          disabled={actionsDisabled}
                        >
                          <EditIcon color="primary" />
                        </TableCellAction>
                      )}
                      {!hideDelete && (
                        <TableCellAction
                          title="Deletar"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleDelete(row);
                          }}
                          disabled={actionsDisabled}
                        >
                          <DeleteIcon color="error" />
                        </TableCellAction>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {dataSource.length === 0 && (
              <Typography style={{ margin: '10px' }} variant="subtitle2" gutterBottom>
                Nenhum resultado encontrado.
              </Typography>
            )}
            {filteredRows != null && dataSource.length > 0 && filteredRows.length === 0 && (
              <Typography style={{ margin: '10px' }} variant="subtitle2" gutterBottom>
                Nenhum resultado encontrado para a pesquisa.
              </Typography>
            )}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            labelRowsPerPage="Linhas por página"
            component="div"
            count={dataSource.length}
            onPageChange={(event, page) => {
              gotoPage(page);
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            page={pageIndex}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
      </Card>
    </React.Fragment>
  );
}

SimpleReactTable.defaultProps = {
  onSelectCallback: null,
  hideEdit: false,
  hideDelete: false,
  onEditCallback: () => {},
  onDeleteCallback: () => {},
  actionsDisabled: false,
  size: 'small',
};

export default SimpleReactTable;
