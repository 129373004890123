/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import showNotification from '../../commons/helpers/showNotification';
import SuperUserService from '../../services/SuperUserService';
import yup from '../../commons/validators/customYup';
import { FormBooleanRadioGroup, FormDateField, FormRadioGroup } from '../../components/forms/FormikFields';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OriginalAppointmentOnlineModal from './modals/OriginalAppointmentOnlineModal';
import SimpleReactTable from '../../components/table/SimpleReactTable';

const schema = yup.object().shape({
  startDate: yup
    .date()
    .nullable()
    .max(yup.ref('endDate'), 'Data Inicio não pode ser maior que Data Fim.')
    .required(),
  endDate: yup.date().nullable().required(),
});
const EXCLUDE_DELETED = 'EXCLUDE_DELETED';
const INCLUDE_DELETED = 'INCLUDE_DELETED';
const ALL_RECORDS = 'ALL_RECORDS';
const DELETED_RECORDS = { EXCLUDE_DELETED, INCLUDE_DELETED, ALL_RECORDS };

const INIT_VALUES = {
  startDate: null,
  endDate: null,
  byAppointmentDate: true,
  deletedRecords: DELETED_RECORDS.EXCLUDE_DELETED,
  customer: null,
  employee: null,
};

// COMPONENT
function CompanyAppointmentSearchPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [resultsInfo, setResultsInfo] = useState();
  const [modalOriginalAppointmentOnline, setModalOriginalAppointmentOnline] = useState({ open: false });

  const location = useLocation();
  const state = location.state;
  const company = state?.company;
  const companyId = company?.id;

  const myHandleSubmit = (values, actions) => {
    const deletedRecords = values.deletedRecords === DELETED_RECORDS.INCLUDE_DELETED ? true : false;
    const allRecords = values.deletedRecords === DELETED_RECORDS.ALL_RECORDS ? true : false;
    setIsLoading(true);
    SuperUserService.getAppointmentReports({
      companyId: companyId,
      employeeId: values.employee ? values.employee.id : null,
      clientId: values.customer ? values.customer.id : null,
      startDate: values.startDate.format('YYYY-MM-DD'),
      endDate: values.endDate.format('YYYY-MM-DD'),
      byActivityDate: !values.byAppointmentDate,
      deleted: deletedRecords,
      allRecords: allRecords,
    })
      .then((resp) => {
        if (resp.length === 0) {
          showNotification('warning', 'Nenhum resultado encontrado.');
        }
        setResultsInfo(
          resp.map((app) => {
            return {
              ...app,
              dateFormatted: dayjs(app.date, 'YYYY-MM-DD').format('L'),
              timeFormatted: `${app.startTime} - ${app.duration} min`,
              createdAtFormatted: app.createdAt ? dayjs(app.createdAt).format('DD/MM/YYYY HH:mm:ss') : '',
              updatedAtFormatted: app.updatedAt ? dayjs(app.updatedAt).format('DD/MM/YYYY HH:mm:ss') : '',
              deletedAtFormatted: app.deletedAt ? dayjs(app.deletedAt).format('DD/MM/YYYY HH:mm:ss') : '',
              onlineScheduled: app.createdByName === 'Agendamento Online',
            };
          }),
        );
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'onlineScheduled',
        noFilter: true,
        Cell: ({ cell, row }) => {
          if (row.original.onlineScheduled) {
            return (
              <FontAwesomeIcon
                color="blue"
                icon="globe"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setModalOriginalAppointmentOnline({ open: true, appointmentId: row.original.id });
                }}
              />
            );
          }
          return null;
        },
      },
      {
        Header: 'Profissional',
        accessor: 'professionalName',
      },
      {
        Header: 'Data',
        accessor: 'date',
        Cell: (object) => object.row.original.dateFormatted,
        inputFilterType: 'date',
      },
      {
        Header: 'Horario',
        accessor: 'timeFormatted',
      },
      {
        Header: 'Serviço',
        accessor: 'serviceName',
      },
      {
        Header: 'Cliente',
        accessor: 'customerName',
      },
      {
        Header: 'Telefone',
        accessor: 'customerPhone1',
      },
      {
        Header: 'Criado em',
        accessor: 'createdAtFormatted',
      },
      {
        Header: 'Criado por',
        accessor: 'createdByName',
      },
      {
        Header: 'Atualizado em',
        accessor: 'updatedAtFormatted',
      },
      {
        Header: 'Atualizado por',
        accessor: 'updatedByName',
      },
      {
        Header: 'Deletado em',
        accessor: 'deletedAtFormatted',
      },
      {
        Header: 'Deletado por',
        accessor: 'deletedByName',
      },
    ],
    [],
  );

  const initialState = React.useMemo(() => ({ pageSize: 25, sortBy: [{ id: 'date', desc: true }] }), []);

  return (
    <Box>
      {/* MODALS */}
      {modalOriginalAppointmentOnline.open && (
        <OriginalAppointmentOnlineModal
          appointmentId={modalOriginalAppointmentOnline.appointmentId}
          companyId={companyId}
          open={modalOriginalAppointmentOnline.open}
          onClose={() => {
            setModalOriginalAppointmentOnline((prev) => ({ ...prev, open: false }));
          }}
        />
      )}
      {/* MODALS */}

      <Formik
        enableReinitialize
        initialValues={INIT_VALUES}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Typography variant="subtitle1">Relatório de Agendamento</Typography>
              {company && (
                <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
                  <Typography sx={{ mr: 2 }}>
                    <Typography variant="caption">Nome:</Typography> {company.name}
                  </Typography>
                  <Typography>
                    <Typography variant="caption">Email:</Typography> {company.email}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
              <Typography>Atalhos</Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button
                  onClick={() => {
                    setFieldValue('startDate', dayjs().subtract(1, 'day'));
                    setFieldValue('endDate', dayjs().subtract(1, 'day'));
                  }}
                >
                  Ontem ({dayjs().subtract(1, 'day').format('DD/MM/YYYY')})
                </Button>
                <Button
                  onClick={() => {
                    setFieldValue('startDate', dayjs());
                    setFieldValue('endDate', dayjs());
                  }}
                >
                  Hoje ({dayjs().format('DD/MM/YYYY')})
                </Button>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 2 }}>
              <FormBooleanRadioGroup
                labelFalse="Atividade"
                labelTrue="Agendamento"
                name="byAppointmentDate"
                row
              />

              <FormDateField
                name="startDate"
                label={`Data Inicio ${values.byAppointmentDate ? 'Agendamento' : 'Atividade'}`}
                sx={{ ml: 2 }}
              />
              <FormDateField
                name="endDate"
                label={`Data Fim ${values.byAppointmentDate ? 'Agendamento' : 'Atividade'}`}
                sx={{ ml: 2, mr: 2 }}
              />

              {/* <ExportAppointmentsIntenalFilterByCustomer
                companyId={companyId}
                selectedCustomer={values.customer}
                setSelectedCustomer={(newCustomer) => {
                  setFieldValue('customer', newCustomer);
                }}
              /> */}

              <FormRadioGroup
                name="deletedRecords"
                dataSource={[
                  {
                    id: DELETED_RECORDS.EXCLUDE_DELETED,
                    name: 'Excluir Deletados',
                  },
                  {
                    id: DELETED_RECORDS.INCLUDE_DELETED,
                    name: 'Apenas Deletados',
                  },
                  {
                    id: DELETED_RECORDS.ALL_RECORDS,
                    name: 'Todos',
                  },
                ]}
                row
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={companyId == null || isSubmitting || isLoading}
                sx={{ ml: 2 }}
              >
                Buscar {(isSubmitting || isLoading) && <CircularProgress />}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {resultsInfo != null && resultsInfo.length > 0 && (
        <SimpleReactTable
          id="atendimentos"
          dataSource={resultsInfo}
          columns={columns}
          initialState={initialState}
          hideDelete
          hideEdit
        />
      )}
    </Box>
  );
}

export default CompanyAppointmentSearchPage;
