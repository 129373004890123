import React, { useState, useEffect } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  Box,
  CircularProgress,
  DialogContent,
  Table,
  TableRow,
  TableCell,
  DialogActions,
  TableBody,
} from '@mui/material';
import SuperUserService from '../../../services/SuperUserService';
import showNotification from '../../../commons/helpers/showNotification';
import dayjs from 'dayjs';

// COMPONENT
function OriginalAppointmentOnlineModal({ appointmentId, companyId, open, onClose }) {
  const [dataSource, setDataSource] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      SuperUserService.getOriginalAppointmentOnline({
        companyId: companyId,
        appointmentId: appointmentId,
      })
        .then((resp) => {
          if (resp == null) {
            showNotification('warning', 'Nenhum resultado encontrado.');
          }
          setDataSource(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, appointmentId, companyId]);

  return (
    <>
      {/* DIALOG */}
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">Dados Originais do Agendamento Online</Typography>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              {dataSource && (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <strong>Profissional</strong>
                      </TableCell>
                      <TableCell>{dataSource.user?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Data</strong>
                      </TableCell>
                      <TableCell>
                        {dataSource.date ? dayjs(dataSource.date).format('DD/MM/YYYY') : ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Horario</strong>
                      </TableCell>
                      <TableCell>{dataSource.startTime}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Duração</strong>
                      </TableCell>
                      <TableCell>{dataSource.duration} min</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Serviço</strong>
                      </TableCell>
                      <TableCell>{dataSource.serviceName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Cliente</strong>
                      </TableCell>
                      <TableCell>{dataSource.customerName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Telefone Cliente</strong>
                      </TableCell>
                      <TableCell>{dataSource.customer?.phone1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Data Criação</strong>
                      </TableCell>
                      <TableCell>
                        {dataSource.createdAt
                          ? dayjs(dataSource.createdAt).format('DD/MM/YYYY HH:mm:ss')
                          : ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OriginalAppointmentOnlineModal;
