import React, { useEffect } from 'react';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import yup from '../../commons/validators/customYup';
import { FormCheckbox, FormCurrencyField, FormDateField, FormTextField } from '../forms/FormikFields';

const DialogActionsStyled = styled(DialogActions)(({ theme }) => {
  return {
    justifyContent: 'space-between',
    margin: '10px',
  };
});

const schema = yup.object().shape({
  textConfirmation: yup.string().max(150),
  observation: yup.string().min(5).max(150),
  amount: yup.number(),
});

const DEFAULT_INIT_VALUES = {
  textConfirmation: '',
  observation: '',
  amount: 0,
  date: null,
  checkBox: false,
};

// COMPONENT
function ConfirmationDialog({
  title,
  message,
  open,
  onClose,
  onConfirm,
  onCancel,
  textConfirm,
  loading,
  note,
  amount,
  dateFieldLabel,
  checkBoxFieldLabel,
  messageStyle,
  alert,
}) {
  const [initialValues, setInitialValues] = React.useState(DEFAULT_INIT_VALUES);
  const [isLoading, setIsLoading] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  const isMyLoading = isLoading || loading;

  useEffect(() => {
    if (open) {
      setInitialValues({
        textConfirmation: '',
        observation: '',
        amount: amount,
        date: null,
        checkBox: false,
      });

      if (textConfirm) {
        setConfirmation(false);
      } else if (note) {
        setConfirmation(false);
      } else {
        setConfirmation(true);
      }
    }
  }, [open, amount, textConfirm, note]);

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };

  const handleChange = (value) => {
    if (textConfirm) {
      if (textConfirm.toLowerCase() === value.textConfirmation.toLowerCase()) {
        setConfirmation(true);
      } else {
        setConfirmation(false);
      }
    } else if (note) {
      if (value.observation !== '') {
        setConfirmation(true);
      } else {
        setConfirmation(false);
      }
    } else {
      setConfirmation(true);
    }
  };

  const handleOk = (values) => {
    const promise = onConfirm(values);
    if (promise) {
      setIsLoading(true);
      promise.finally(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <Dialog maxWidth="xs" onClose={onClose} aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleOk}>
        {({ dirty, isSubmitting, values, isValid }) => {
          return (
            <Form>
              <DialogContent dividers>
                <Typography style={messageStyle}>{message}</Typography>
                {alert && (
                  <Alert severity="warning" style={{ marginBottom: '8px' }}>
                    {alert}
                  </Alert>
                )}

                <Box>
                  {textConfirm && (
                    <FormTextField
                      label={`Digite a confirmação abaixo: ${textConfirm}`}
                      fullWidth
                      name="textConfirmation"
                      inputProps={{
                        onKeyUp: () => {
                          handleChange(values);
                        },
                        onPaste: (e) => {
                          e.preventDefault();
                          return false;
                        },
                        autoComplete: 'off',
                      }}
                    ></FormTextField>
                  )}
                  {amount && <FormCurrencyField label="Valor" decimalScale={2} fullWidth name="amount" />}
                  {note && (
                    <FormTextField
                      label={note}
                      fullWidth
                      name="observation"
                      inputProps={{
                        onKeyUp: () => {
                          handleChange(values);
                        },
                      }}
                    />
                  )}
                  {dateFieldLabel && <FormDateField label={dateFieldLabel} fullWidth name="date" />}
                  {checkBoxFieldLabel && (
                    <FormCheckbox label={checkBoxFieldLabel} name="checkBox" sx={{ mt: 1 }} />
                  )}
                </Box>
              </DialogContent>
              <DialogActionsStyled>
                <Button disabled={isMyLoading} variant="outlined" onClick={handleCancel}>
                  Fechar
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isMyLoading || isSubmitting || !isValid || !confirmation}
                >
                  Sim
                  {isMyLoading && <CircularProgress size={12} />}
                </Button>
              </DialogActionsStyled>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default ConfirmationDialog;
